<template>
    <a 
      v-if="visible"
      :href="url"
      class="menu-item"
      :target="newtab == '1' ? '_blank' : '_self'">
      <IconSax :name="icon" primary />
      {{ label }}
    </a>
</template>
<script>

import {mapGetters} from 'vuex'
import {validation} from "@/utils/validation";
import {boolVal} from "@/utils/bool";

export default {
  props: ['index', 'course_classes', 'signatures'],
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings"
    }),
    icon() {
        return this.getSettings[`link_menu_${this.index}_icon`] || "link"
    },
    label() {
        return this.getSettings[`link_menu_${this.index}_label`]
    },
    url() {
        return this.getSettings[`link_menu_${this.index}_url`]
    },
    newtab() {
        return this.getSettings[`link_menu_${this.index}_newtab`]
    },
    conditional() {
      return this.getSettings[`link_menu_${this.index}_conditional`]
    },
    type() {
      return this.getSettings[`link_menu_${this.index}_type`]
    },
    course_class_ids() {
      return this.getSettings[`link_menu_${this.index}_course_class_ids`]
    },
    signature_ids() {
      return this.getSettings[`link_menu_${this.index}_signature_ids`]
    },
    visible() {
      if (validation.isEmpty(this.url) || validation.isEmpty(this.label)) {
        return false
      }

      if (!boolVal(this.conditional)) {
        return true
      }

      const courseClasses = JSON.parse(this.course_class_ids) ?? [];
      const signatures = JSON.parse(this.signature_ids) ?? [];

      if (this.type === 'course_class') {
        return this.course_classes.find(c => courseClasses.includes(c))
      } else {
        return this.signatures.find(s => signatures.includes(s))
      }
    }
  }
}

</script>
<style lang="scss" scoped>

.menu-item {
  @include flex-row;
  width: 13.0625rem;
  padding: 0.625rem 0rem;
  color: var(--fontcolor);
  align-items: center;
  gap: 1rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  ::v-deep svg {
    width: 1.25rem;
    height: 1.25rem;
    path {
      fill: var(--maincolor);
    }
  }

  &.router-link-active {
    color: var(--maincolor);
  }

  &:hover {
    color: var(--maincolor);
  }
}

</style>