<template>
  <element @click="$emit('click')" :is="tag" :class="textClasses" :style="style">
    <slot>{{ text }}</slot>
  </element>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "p",
      validator: (value) =>
        ["p", "span", "label", "strong", "div"].includes(value),
    },
    text: {
      type: [Number, String],
      default: "",
    },
    size: {
      type: String,
      default: "xs",
      validator: (value) =>
        [
          "xxs",
          "xs",
          "sm",
          "base",
          "md",
          "lg",
          "xl",
          "2xl",
          "3xl",
          "4xl",
          "5xl",
          "6xl",
          "7xl",
          "8xl",
          "9xl",
        ].includes(value),
    },
    color: {
      type: String,
      default: '#000'
    },
    bgColor: {
      type: String,
    },
    weight: {
      type: String,
      default: "normal",
      validator: (value) =>
        [
          "thin",
          "extralight",
          "light",
          "normal",
          "medium",
          "semibold",
          "bold",
          "extrabold",
          "black",
        ].includes(value),
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
    html: {
      type: String,
    },
    state: {
      type: "default" | "danger" | "warning" | "success",
      default: "default"
    },
    decoration: {
      type: String,
      default: "none",
      validator: (value) =>
        ["none", "underline", "line-through"].includes(value),
    },
  },
  computed: {
    textClasses() {
      const textSize = "text-" + this.size;
      const textWeight = "font-" + this.weight;
      const state = `state-${this.state}`;

      return {
        "badge-with-text": !!this.text,
        "badge-play": true,
        "skeleton-play": this.skeleton,
        [state]: true,
        [textSize]: true,
        [textWeight]: true,
      };
    },
    style() {
      return {
        textDecoration: this.decoration,
        backgroundColor: this.bgColor
      };
    },
  },
};
</script>

<style lang="scss" scoped>
// general settings
.badge-play {
  border-radius: 999px;
  margin: 0;
  padding: 0.5rem;

  &.badge-with-text {
    padding: 0.5rem 0.75rem;
  }

  &.state-default {
    background-color: var(--badge-bg-default);
  }

  &.state-danger {
    background-color: var(--feedback-error-200);
    color: var(--feedback-error-700);
  }

  &.state-warning {
    background-color: var(--feedback-warning-200);
    color: var(--feedback-warning-700);
  }

  &.state-success {
    background-color: var(--feedback-success-200);
    color: var(--feedback-success-700);
  }

}

// font sizes
.text-xxs {
  font-size: 0.625rem;
  /* 10px */
  line-height: 1rem;
  /* 16px */
}

.text-xs {
  font-size: 0.75rem;
  /* 12px */
  line-height: 1rem;
  /* 16px */
}

.text-sm {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
}

.text-base,
.text-md {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
}

.text-lg {
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
}

.text-xl {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.75rem;
  /* 28px */
}

.text-2xl {
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
}

.text-3xl {
  font-size: 1.875rem;
  /* 30px */
  line-height: 2.25rem;
  /* 36px */
}

.text-4xl {
  font-size: 2.25rem;
  /* 36px */
  line-height: 2.5rem;
  /* 40px */
}

.text-5xl {
  font-size: 3rem;
  /* 48px */
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  /* 60px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  /* 72px */
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  /* 96px */
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  /* 128px */
  line-height: 1;
}

// font weights
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}
</style>
