<template>
  <SidebarModal :visible="true" id="modal-member-create" size="lg" @close="$emit('close')">
    <template slot="content">
      <section v-if="view == 'main'" class="flex flex-col gap-1 content">
        <TextPlay :text="$t('minha_conta.purchases.labels.order_summary')" v-bind="legendProps" />
        <div v-if="isSubscription" class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.contract_id')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="'#' + purchase.contract_id" size="sm" weight="bold" color="var(--title-color)" />
        </div>
        <div v-else class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.order_number')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="'#' + purchase.id" size="sm" weight="bold" color="var(--title-color)" />
        </div>
        <div class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.product')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="purchase.product_name" size="sm" color="var(--title-color)" />
        </div>
        <div v-if="isSubscription" class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.subscription')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="$t(`minha_conta.purchases.labels.subscription_period.${purchase.subscription_period}`)"
            size="sm" color="var(--title-color)" />
        </div>
        <div class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.total')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="formatMoney(purchase.total)" size="sm" weight="bold" color="var(--title-color)" />
        </div>
        <DividerPlay />
        <TextPlay :text="$t('minha_conta.purchases.labels.transaction_details')" v-bind="legendProps" />
        <div class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.sale_at')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="formatDate(purchase.created_at)" size="sm" color="var(--title-color)" />
        </div>
        <div class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.payment')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="getPaymentMethod" size="sm" color="var(--title-color)" />
        </div>
        <div class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.status')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="getStatusLabel(purchase.status)" size="sm" :color="getStatusColor(purchase.status)" />
        </div>
        <DividerPlay />
        <TextPlay :text="$t('minha_conta.purchases.labels.seller_data')" v-bind="legendProps" />
        <div class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.name')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="purchase.seller_name" size="sm" color="var(--title-color)" />
        </div>
        <div class="flex flex-row justify-between">
          <TextPlay :text="$t('minha_conta.purchases.labels.email')" size="sm" color="var(--subtitle-color)" />
          <TextPlay :text="purchase.seller_email" size="sm" color="var(--title-color)" />
        </div>
        <ButtonPlay v-if="isCreditCard && isSubscription" text="Atualizar dados do cartão de crédito" size="sm"
          @click="paymentUpdate" />
        <ButtonPlay v-if="getInstallmentCount" text="Ver histórico de transações" size="sm"
          @click="openView('transaction')" />
        <ButtonPlay v-if="isSubscription && getSettings.feature_cancel_subscription" text="Cancelar assinatura"
          size="sm" variant="danger" @click="openView('cancel')" />
      </section>
      <section v-if="view == 'transaction'" class="flex flex-col gap-1 content">
        <TextPlay text="Histórico de transações:" v-bind="legendProps" />
        <div class="flex flex-row">
          <TextPlay :text="$t('minha_conta.purchases.labels.installment')" size="sm" weight="bold"
            color="var(--subtitle-color)" class="flex flex-1 justify-start" />
          <TextPlay :text="$t('minha_conta.purchases.labels.installment_duedate')" size="sm" weight="bold"
            color="var(--subtitle-color)" class="flex flex-1 justify-center" />
          <TextPlay :text="$t('minha_conta.purchases.labels.installment_status')" size="sm" weight="bold"
            color="var(--subtitle-color)" class="flex flex-1 justify-end" />
          <TextPlay :text="$t('minha_conta.purchases.labels.installment_amount')" size="sm" weight="bold"
            color="var(--subtitle-color)" class="flex flex-1 justify-end" />
        </div>
        <div v-for="installment in getInstallments" track-by="id" class="flex flex-row">
          <TextPlay :text="getInstallmentTitle(installment)" size="sm" color="var(--title-color)"
            class="flex flex-1 justify-start" />
          <TextPlay :text="formatDate(installment.due_date)" size="sm" color="var(--title-color)"
            class="flex flex-1 justify-center" />
          <TextPlay :text="getStatusLabel(installment.status)" size="sm" :color="getStatusColor(installment.status)"
            class="flex flex-1 justify-end" />
          <TextPlay :text="formatMoney(installment.amount)" size="sm" color="var(--title-color)"
            class="flex flex-1 justify-end" />
        </div>
        <ButtonPlay :text="$t('back')" class="mt-2" @click="backToMain" />
      </section>
      <section v-if="view == 'cancel'" class="flex flex-col gap-1 content">
        <TextPlay :text="$t('minha_conta.purchases.labels.cancel_signature')" v-bind="legendProps" />
        <div>
          <p class="cancel-title">{{ $t("minha_conta.purchases.labels.cancel_signature_text") }}</p>
        </div>
        <ButtonPlay :text="$t('minha_conta.purchases.labels.cancel_signature')" variant="danger" class="mt-2"
          @click="backToMain" />
        <ButtonPlay :text="$t('back')" class="mt-2" @click="backToMain" />
      </section>
    </template>
  </SidebarModal>
</template>

<script>
import fp from "lodash/fp"
import moment from "moment"
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      view: "main",
      legendProps: {
        weight: "bold",
        size: "sm",
        color: "var(--title-color)",
      },
    };
  },
  props: {
    purchase: {}
  },
  methods: {
    openView(view) {
      this.view = view;
    },
    backToMain() {
      this.view = "main";
    },
    formatMoney(amount) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(amount)
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    paymentUpdate() {
      window.open(this.purchase.payment_update_link, '_blank').focus();
    },
    getStatusColor(status) {
      if (["paid", "completed"].includes(status)) {
        return "var(--feedback-success-500)"
      }
      if (["delayed", "ended", "refused"].includes(status)) {
        return "var(--feedback-error-500)"
      }
      if (["processing", "waiting_payment"].includes(status)) {
        return "var(--feedback-warning-500)"
      }
      return "var(--title-color)";
    },
    getInstallmentTitle(installment) {
      return [
        installment.number.toString().padStart(2, '0'),
        this.$t('of'),
        this.getInstallmentCount.toString().padStart(2, '0'),
      ].join(' ')
    },
    getStatusLabel(status) {
      return this.$t(`minha_conta.purchases.status.${status}`)
    },
  },
  computed: {
    ...mapGetters({
      'getSettings': "config/getSettings"
    }),
    getInstallments() {
      return this.purchase.installments.reverse()
    },
    getPaymentMethod() {
      let label = this.$t(`minha_conta.purchases.payment_method.${this.purchase.payment_method}`)
      let installment_label = '';

      if (this.getInstallmentCount) {
        installment_label = [
          this.getChargesCount.toString().padStart(2, '0'),
          this.$t(`of`),
          this.getInstallmentCount.toString().padStart(2, '0'),
        ].join(' ')
      } else if (this.isSubscription && this.getChargesCount) {
        label = [
          this.$t(`of`),
          this.getChargesCount.toString().padStart(2, '0')
        ].join(' ')
      }

      return fp.compact([
        label,
        installment_label
      ]).join(' - ')
    },
    getChargesCount() {
      return fp.size(this.purchase.installments);
    },
    getInstallmentCount() {
      return this.purchase.installment_count;
    },
    isCreditCard() {
      return this.purchase.payment_method == 'CREDIT_CARD';
    },
    isSubscription() {
      return this.purchase.transaction_type == "SUBSCRIPTION";
    }
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 4rem;
}

.content {
  max-width: 30rem;
}

.cancel-title {
  text-wrap: wrap;
  width: 25rem;
  color: var(--subtitle-color);
  white-space: pre-wrap;
}
</style>
