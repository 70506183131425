<template>
  <Modal
      :visible="true"
      id="modal-conditional-links"
      size="lg"
      :title="$t('members.modal_conditional_links.title')"
      tag="form"
      @submit="submit"
      @close="dispose">
    <p class="title">{{$t('members.modal_conditional_links.txt01')}}</p>

    <draggable :list="links" handle=".drag-handle" class="d-flex flex-column gap-1">
      <template v-for="(link, index) in links">
        <element
            is="ConditionalLink"
            :link="link"
            :key="index"
            :index="index"
            :title="`Link ${index + 1}`"
            @remove="deleteLink(index)"
        />
      </template>
    </draggable>

    <template v-slot:footer>
      <div class="row gap-1">
        <div class="col">
          <ButtonPlay
              v-if="links.length < 4"
              type="clear"
              color="var(--maincolor)"
              borderColor="transparent"
              borderColorOnHover="transparent"
              bgColorOnHover="transparent"
              @click="createLink"
              :text="$t('members.modal_conditional_links.buttons.add')"
              icon="add-circle"
              dense
          />
        </div>
        <div class="col-auto">
          <ButtonPlay
              type="normal"
              bgColor="transparent"
              bgColorOnHover="transparent"
              color="var(--maincolor)"
              @click="dispose"
              :text="$t('members.modal_conditional_links.buttons.cancel')"
          />
        </div>
        <div class="col-auto">
          <ButtonPlay
              type="normal"
              :text="$t('members.modal_conditional_links.buttons.save')"
              submit
              :loading="loading"
              loader="spinner"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import ConditionalLink from "@/components/configs/ConditionalLink.vue";
import ButtonPlay from "@/components/common/ButtonPlay.vue";
import draggable from "vuedraggable";
import {mapActions, mapGetters} from "vuex";
import {ConditionalLinkAdapter} from "@/classes/ConditionalLinkAdapter";
import {validation} from "@/utils/validation";
import {boolVal} from "@/utils/bool";

export default {
  components: {
    ButtonPlay,
    ConditionalLink,
    draggable
  },
  props: {
    history: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      links: [],
      version: null,
      loading: false,
    };
  },
  watch: {
    links() {
      this.commit()
    }
  },
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings"
    })
  },
  methods: {
    ...mapActions({
      saveConditionalLinks: "config/actionSaveConditionalLinks",
      actionShowModal: 'app/actionShowModal',
    }),

    /**
     * Create new link
     * @param {ConditionalLinkAdapter|Object} link
     */
    createLink(link = {}) {
      if (this.links.length < 4) {
        const item = !(link instanceof ConditionalLinkAdapter )
            ? new ConditionalLinkAdapter(link)
            : link;

        this.links.push(item);
      }
    },

    /**
     * Delete a link
     * @param {Number} index
     */
    deleteLink(index) {
      this.actionShowModal({
        id: "ModalConditionalLinksDelete",
        confirm: () => this.links.splice(index, 1)
      })
    },

    /**
     * Fetch stored links
     */
    fetchLinks() {
      for (let index = 1; index <= 4; index++) {
        const label = this.getSettings[`link_menu_${index}_label`]
        const url = this.getSettings[`link_menu_${index}_url`]
        const conditional = this.getSettings[`link_menu_${index}_conditional`]
        const newtab = this.getSettings[`link_menu_${index}_newtab`]

        if (label || url) {
          this.createLink({
            index,
            label,
            url,
            icon: this.getSettings[`link_menu_${index}_icon`],
            newtab: boolVal(newtab),
            conditional: boolVal(conditional),
            type: this.getSettings[`link_menu_${index}_type`],
            signature_ids: JSON.parse(this.getSettings[`link_menu_${index}_signature_ids`]) ?? [],
            course_class_ids: JSON.parse(this.getSettings[`link_menu_${index}_course_class_ids`]) ?? [],
          })
        }
      }
    },

    /**
     * Validate basic link information
     * @param link
     * @returns {boolean}
     */
    validate(link) {
      let invalid = []

      if (validation.isEmpty(link?.icon)) {
        invalid.push('icon')
      }

      if (validation.isEmpty(link?.label)) {
        invalid.push('name')
      }

      if (validation.isEmpty(link?.url)) {
        invalid.push('url')
      }

      const h = this.$createElement;
      const message = h("div", {}, [
        h('p', { class: 'm-0' }, this.$t("members.modal_conditional_links.toasts.required_fields")),
        h('ul', { class: 'px-3 m-0' }, invalid.map(i => {
          return h('li', { class: 'fw-600' }, this.$t(`members.modal_conditional_links.labels.${i}`))
        }))
      ])

      if (invalid.length > 0) {
        this.$bvToast.toast(message, {
          title: this.$t("members.modal_conditional_links.toasts.failed"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });

        return false
      }

      return true
    },

    /**
     * Validate link conditionals
     * @param link
     * @returns {boolean}
     */
    validateConditionals(link) {
      let error = false

      if (link.conditional && !link.type) {
        error = true
      }

      if (link.type === "course_class" && link.course_class_ids.length === 0) {
        error = true
      }

      if (link.type === "signature" && link.signature_ids.length > 0) {
        error = true
      }

      if (error) {
        this.$bvToast.toast(this.$t("members.modal_conditional_links.toasts.required_liberation"), {
          title: this.$t("members.modal_conditional_links.toasts.failed"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });

        return false
      }

      return true;
    },

    /**
     * Validate and submit form
     * @returns {boolean}
     */
    submit() {
      this.loading = true

      try {
        this.links.forEach(link => {
          if (!this.validate(link) || !this.validateConditionals(link)) {
            throw 'Validation error'
          }
        })
      } catch (e) {
        this.loading = false
        return false
      }

      this.save().finally(() => {
        this.loading = false
      })

      return false;
    },

    /**
     * Save changes
     * @returns {Promise<void>}
     */
    async save() {
      let error = false;

      this.saveConditionalLinks(this.links).catch(() => {
        error = true;
      })

      if (error) {
        this.$bvToast.toast(this.$t("members.modal_conditional_links.toasts.failed_message"), {
          title: this.$t("members.modal_conditional_links.toasts.failed"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });

        this.commit()
      } else {
        this.$root.$bvToast.toast(this.$t("members.modal_conditional_links.toasts.success_message"), {
          title: this.$t("members.modal_conditional_links.toasts.success"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });

        this.$emit('close')
      }
    },

    /**
     * Makes a trash to restore changes in links and close modal
     */
    dispose() {
      this.$emit('close')

      const currentVersion = JSON.stringify(this.links);
      if (currentVersion !== this.version) {
        this.actionShowModal({
          id: "ModalConditionalLinksDiscard",
          data: this.links,
        });
      }
    },

    /**
     * Commit locally link changes in a version
     */
    commit() {
      this.version = JSON.stringify(this.links);
    },
  },
  mounted() {
    /**
     * Check if it has a previous trashed version to restore
     */
    if (this.history.length > 0) {
      this.history.forEach(link => this.createLink(link));
    } else {
      this.fetchLinks()
    }

    this.commit()
  }
};
</script>
<style lang="scss">
#modal-conditional-links {
  .modal-dialog {
    width: 792px;
  }

  .header .headerTitle {
    font-weight: 600;
    font-size: 20px;
  }

  .footer {
    padding-top: 20px;
  }

  .container_wrap {
    display: none;
  }

  .title, label {
    color: #B5B5B5;
    font-weight: 400;
  }

  .title {
    margin-bottom: 40px;
  }

  label {
    color: white;
    font-weight: 600;
  }

  .multiselect__input {
    font-weight: 400;
  }
}

[data-theme="light"] {
  #modal-conditional-links {
    .title {
      color: var(--neutral-gray-700);
    }

    label {
      color: var(--neutral-gray-900);
    }
  }
}
</style>
