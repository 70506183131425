<template>
  <Modal
      :visible="true"
      id="modal-conditional-links-delete"
      size="lg"
      :title="$t('members.modal_conditional_links.modal_delete.title')"
      @close="$emit('close')">
    <p class="txt01">{{$t('members.modal_conditional_links.modal_delete.txt01')}}</p>

    <template slot="footer">
      <div class="d-flex flex-column gap-1">
        <ButtonPlay
            type="normal"
            bg-color="var(--feedback-error-500)"
            bg-color-on-hover="feedback-error-500"
            border-color="var(--feedback-error-500)"
            border-color-on-hover="feedback-error-500"
            icon="trash"
            @click="confirmDelete"
            :text="$t('members.modal_conditional_links.buttons.delete')"
        />
        <ButtonPlay
            type="outline"
            bgColor="transparent"
            bgColorOnHover="transparent"
            color="var(--maincolor)"
            @click="$emit('close')"
            :text="$t('members.modal_conditional_links.buttons.cancel')"
        />
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  props: {
    confirm: {
      type: Function,
      required: true,
    }
  },
  methods: {
    confirmDelete() {
      this.confirm()
      this.$emit('close')
    }
  }
};
</script>
<style lang="scss">
#modal-conditional-links-delete {
  .modal-dialog {
    width: 485px;
    height: 324px;
  }

  .modal-body .h-full {
    height: 100%;
  }

  .container_wrap {
    display: none;
  }

  .headerTitle {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 24px;
    line-height: 26.4px;
  }

  .txt01 {
    color: var(--neutral-gray-300);
    font-size: 16px;
    margin-bottom: 24px;
  }
}

[data-theme="light"] {
  #modal-conditional-links-delete {
    .headerTitle {
      color: var(--neutral-gray-900);
    }

    .txt01 {
      color: var(--neutral-gray-700);
    }
  }
}
</style>
