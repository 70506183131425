<template>
	<div
		class="input-play-container"
		:class="{ ...computedClass, 'input-play-container--is-valid': !errors.has(name), 'input-play-container--is-invalid': errors.has(name) }"
		:style="inputContainerStyle"
	>
		<div
			v-if="leftIcon"
			class="input-play-container__left-icon"
		>
			<IconSax
				@click="onClickLeftIcon"
				:name="leftIcon"
				color="var(--input-border-color)"
				size="1rem"
			/>
		</div>
		<input
			:id="id"
			:disabled="disabled"
			:value="handleValue"
			:readonly="readonly"
			v-validate="validation"
			class="input-play"
			:name="name"
			:min="min"
			:max="max"
			:oninput="oninput"
			:type="type"
			:placeholder="placeholder"
			:maxlength="maxlength"
			onwheel="this.blur()"
			autocomplete="off"
			@input="handleInput"
			@blur="validateField"
			@keydown.enter="$emit('enter')"
		/>
		<div
			v-if="rightIcon"
			class="input-play-container__right-icon"
		>
			<IconSax
				@click="onClickRightIcon"
				:name="rightIcon"
				color="var(--input-border-color)"
				size="1rem"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		leftIcon: String,
		rightIcon: String,
		name: String,
		placeholder: String,
		validation: [String, Object],
		value: [String, Number],
		id: String,
		skeleton: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		w: {
			type: String,
			default: "100%",
		},
		maxlength: {
			type: [String, Number],
			default: 100,
		},
		type: {
			type: String,
			default: "text",
		},
		min: {
			type: [String, Number],
		},
		oninput: {
			type: String,
			default: "",
		},
		preventNonNumeric: {
			type: Boolean,
			default: false,
		},
		preventDots: {
			type: Boolean,
			default: false,
		},
		preventHyphens: {
			type: Boolean,
			default: false,
		},
		preventLetters: {
			type: Boolean,
			default: false,
		},
		preventSpaces: {
			type: Boolean,
			default: false,
		},
		validateOnBlur: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		preventAlpha: {
			type: Boolean,
			default: false,
		},
		max: {
			type: [String, Number],
		},
	},
	data() {
		return {
			lastValidValue: this.value || "",
		};
	},
	computed: {
		computedClass() {
			return {
				"skeleton-play input-play-container--skeleton": this.skeleton,
				"input-play-container--disabled": this.disabled,
			};
		},
		inputContainerStyle() {
			return {
				width: this.w,
			};
		},
		handleValue() {
			return this.manageCleanups(this.value);
		},
	},
	methods: {
		onClickLeftIcon() {
			this.$emit("leftIconClick");
		},
		validateField() {
			if (this.validateOnBlur) {
				this.$validator.validate(this.name);
			}
		},

		preventNonNumericChars(value) {
			return value.toString().replace(/[^0-9]/g, "");
		},
		preventDotsChars(value) {
			return value.toString().replace(/\./g, "");
		},
		preventHyphensChars(value) {
			return value.toString().replace(/-/g, "");
		},
		preventLettersChars(value) {
			return value.toString().replace(/[a-zA-Z]/g, "");
		},
		preventSpacesChars(value) {
			return value.toString().replace(/\s/g, "");
		},
		preventMinChars(value) {
			return value < this.min ? "" : value;
		},
		preventAlphaChars(value) {
			return value.toString().replace(/[^0-9]/g, "");
		},

		manageCleanups(value) {
			if (value === null || value === undefined) {
				return "";
			}
			if (this.preventNonNumeric) {
				value = this.preventNonNumericChars(value);
			}
			if (this.preventDots) {
				value = this.preventDotsChars(value);
			}
			if (this.preventHyphens) {
				value = this.preventHyphensChars(value);
			}
			if (this.preventLetters) {
				value = this.preventLettersChars(value);
			}
			if (this.preventSpaces) {
				value = this.preventSpacesChars(value);
			}
			if (this.min) {
				value = this.preventMinChars(value);
			}
			if (this.preventAlpha) {
				value = this.preventAlphaChars(value);
			}

			return value;
		},
		handleInput($event) {
			let value = $event.target.value;
			value = this.manageCleanups(value);

			if (this.max && Number(value) > this.max) {
				$event.target.value = this.lastValidValue;
			} else {
				this.lastValidValue = value;
				$event.target.value = value;
				this.$emit("input", value);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.input-play-container {
	display: flex;
	align-items: center;
	border: 1px solid transparent;
	border-radius: 8px;
	height: 48px;
	width: 100%;

	&--is-valid {
		border-color: transparent !important;
	}

	&--is-invalid {
		border-color: var(--feedback-error-500, #ff3932) !important;
	}

	&--skeleton {
		border-radius: 4px;
	}

	&--disabled {
		input {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	&__left-icon,
	&__right-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		height: 100%;
	}

	&__left-icon {
		padding: 0 4px 0 10px;
	}

	&__right-icon {
		padding: 0 10px 0 4px;
	}

	.input-play {
		border-radius: 8px;
		width: 100%;
		background: transparent !important;
    height: 100%;
    border: 1px solid var(--input-border-color);
		padding: 0 12px;
		font-weight: normal;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
		}

    &:invalid {
      border-color: var(--feedback-error-500, #ff3932) !important;
    }
	}
}
</style>
