<template>
	<div class="multiselect-play">
		<div
			v-if="inputLabel || enableSelectAll"
			class="d-flex align-items-center justify-content-between"
		>
			<label
				v-if="inputLabel"
				:for="id"
				>{{ inputLabel ?? $t("multiselect.label") }}</label
			>
			<p
				v-if="enableSelectAll"
				class="toggle-select-all"
				@click="toggleSelectAll()"
			>
				{{ isAllSelected ? $t("multiselect.unselect_all") : $t("multiselect.select_all") }}
			</p>
		</div>

		<Multiselect
			:id="id"
      :ref="reference"
			v-model="selected"
			:options="options"
			:multiple="multiple"
			:close-on-select="closeOnSelect"
			:clear-on-select="clearOnSelect"
			:preserve-search="preserveSearch"
			:deselectLabel="deselectLabel ?? $t('multiselect.press_enter_to_remove')"
			:selectLabel="selectLabel ?? $t('multiselect.press_enter_to_select')"
			:selectedLabel="selectedLabel ?? $t('multiselect.selected')"
			:placeholder="placeholder ?? $t('multiselect.placeholder')"
			:open-direction="openDirection"
			:label="label"
			:track-by="trackBy"
			:preselect-first="preselectFirst"
			:showLabels="showLabels"
      :maxHeight="maxHeight"
			:limit="limit"
			:limitText="count => `+${count}`"
      :taggable="taggable"
      :max="max"
      :hide-selected="hideSelected"
      @tag="tag"
      :class="this.styleClasses"
			@open="$emit('open')"
			@input="$emit('input', $event)"
		>
			<template #option="props">
				<div class="d-flex align-items-center">
					<input
						v-if="multiple"
						type="checkbox"
						:checked="isSelected(props.option)"
						class="mr-2"
					/>
					<div class="option__desc">
						<span class="option__title">{{ props.option[label] }}</span>
					</div>
				</div>
			</template>

			<span slot="noResult">{{ noResult ?? $t("multiselect.not_found") }}</span>

			<span slot="noOptions">{{ noOptions ?? $t("multiselect.no_options") }}</span>
		</Multiselect>

    <span v-if="error && error instanceof String" class="errorMessage">{{error}}</span>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	components: { Multiselect },
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Array, Object, String, Number],
			default: null,
		},
		selectedLabel: {
			type: String,
		},
		enableSelectAll: {
			type: Boolean,
			default: false,
		},
		limit: {
			type: Number,
			default: 3,
		},
    max: {
      type: [Number, null],
      default: null,
    },
		inputLabel: {
			type: String,
		},
		options: {
			type: Array,
			required: true,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		deselectLabel: {
			type: String,
		},
		selectLabel: {
			type: String,
		},
		closeOnSelect: {
			type: Boolean,
			default: false,
		},
		clearOnSelect: {
			type: Boolean,
			default: true,
		},
		preserveSearch: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
		},
		noResult: {
			type: String,
		},
		noOptions: {
			type: String,
		},
		label: {
			type: String,
			default: "label",
		},
		trackBy: {
			type: String,
			default: "id",
		},
		preselectFirst: {
			type: Boolean,
			default: false,
		},
		showLabels: {
			type: Boolean,
			default: false,
		},
		openDirection: {
			type: String
		},
    taggable: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Function,
      default: () => false,
    },
    error: {
      type: [String, Boolean],
      default: null,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
    reference: {
      type: String,
      default: window.crypto.randomUUID(),
    },
    required: {
      type: Boolean,
      default: false,
    }
	},
	data() {
		return {
			id: `multiselect-play-${this._uid}`,
			selected: this.value
		};
	},
	watch: {
		selected(newVal) {
			this.$emit("input", newVal);
		},
	},
	computed: {
		isAllSelected() {
			return this.options?.length === this.selected?.length;
		},
    styleClasses() {
      let classes = []
      if (this.maxHeight === 0) {
        classes.push('no-dropdown')
      }

      if (this.error) {
        classes.push('has-error')
      }

      return classes.join(' ');
    }
	},
	methods: {
		isSelected(option) {
			return this.multiple ? this.selected?.includes(option) : this.selected === option;
		},
		toggleSelectAll() {
			if (this.isAllSelected) {
				this.selected = [];
			} else {
				this.selected = this.options;
			}
		},
    validate() {
      if (this.required && this.selected.length < 1) {
        this.error = true;
      }
    }
	},
  mounted() {
    if (this.required) {
      this.$refs[this.reference].$el.querySelector('input').required = true;

    }
  }
};
</script>

<style lang="scss">
.multiselect-play {
	label {
		font-size: 16px;
		font-weight: 600;
		color: var(--fontcolor);
	}

  .errorMessage {
    font-size: 14px;
    font-weight: 500;
    color: var(--feedback-error-500);
    display: block;
    margin-top: 10px;
  }

	.toggle-select-all {
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: var(--multiselect-toggle-btn-color);
		text-decoration: underline;
		font-size: 12px;
		margin: 0;
	}

	.multiselect {
		&__tags {
			background-color: transparent !important;
			// background-color: var(--multiselect-bg) !important;
			border: 1px solid var(--multiselect-border) !important;
			border-radius: 8px !important;
			overflow: auto;
			max-height: 200px;
			height: auto !important;
			overflow-x: hidden;
		}

		&__single {
			color: white !important;
			font-size: 14px;
			font-weight: 400;
			margin: auto;
		}

		&__strong {
			color: white !important;
			background-color: #2a63ab !important;
			border: 1px solid white !important;
			border-radius: 1rem;
			font-size: 12px;
			padding: 0 6px;
			margin: 4px 4px 0 0;
		}

		&__placeholder,
		&__input::placeholder {
			color: var(--input-placeholder) !important;
			font-weight: 400;
			padding: 0;
			margin: 0;
		}

		&__input {
			margin: 0;

			&:focus {
				margin: 4px 0;
			}
		}

		&__tag {
			background-color: var(--multiselect-badge-bg) !important;
			border: 1px solid var(--multiselect-border) !important;
			font-weight: 500;
			border-radius: 1rem;
			font-size: 12px;
			padding: 4px 6px;
			margin: 4px 4px 0 0;

			span {
				margin-right: 1rem;
				background-color: var(--multiselect-badge-bg) !important;
				color: var(--multiselect-badge-color) !important;
			}

			&-icon {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&-icon:hover {
				background-color: transparent !important;
			}

			&-icon:after {
				color: var(--multiselect-badge-border) !important;
				content: "×";
				font-size: 0.875rem;
			}
		}

		&__option {
			color: var(--multiselect-item-color) !important;
			font-weight: 400 !important;
			font-size: 14px !important;

			&--selected {
				background-color: var(--multiselect-item-selected) !important;
				color: var(--input-color);
			}

			&--highlight {
				background-color: var(--multiselect-item-hover-bg) !important;
			}
		}

		.option__desc {
			flex: 1;
		}

		.option__title {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-wrap: auto;
			text-overflow: ellipsis;
		}

		&__select:before {
			position: relative;
			right: 0;
			top: 20%;
			border: solid black;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 4px;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			border-color: var(--multiselect-border);
			content: " ";
		}

		&__content-wrapper {
			background-color: var(--multiselect-bg) !important;
			border: 1px solid var(--multiselect-border) !important;
			border-radius: 0.25rem;
			margin: 0.3rem 0;
		}

		&__content {
			border-color: transparent !important;
		}

		&__content::-webkit-scrollbar {
      display: none;
		}
	}

  .no-dropdown .multiselect__content-wrapper {
    border: none !important;
    background-color: transparent !important;
  }

  .no-dropdown .multiselect__select {
    display: none !important;
  }

  .has-error .multiselect__tags {
    border-color: var(--feedback-error-500) !important;
  }
}
</style>
