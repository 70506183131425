<template>
  <div class="flex flex-col gap-1">
    <a v-for="purchase in purchases" class="card flex flex-row items-center gap-1 relative" @click="open(purchase)">
      <TextPlay :text="purchase.product_name" weight="bold" class="flex-1 title" />
      <div class="flex flex-row gap-1 tags">
        <BadgePlay :text="getTypeLabel(purchase)" />
        <BadgePlay v-if="isStateInvalid(purchase)" bgColor="var(--feedback-error-500)">
          <IconSax name="info-circle" type="bold" size="1rem" color="var(--neutral-white)" />
        </BadgePlay>
      </div>
      <IconSax name="arrow-right-3" class="indicator" color="var(--maincolor)" />
    </a>
  </div>
</template>

<script>
import IconSax from "@/components/common/IconSax.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      purchases: [],
    };
  },
  methods: {
    ...mapActions({
      actionShowModal: "app/actionShowModal",
      actionGetPurchases: "user/actionGetPurchases"
    }),
    async open(purchase) {
      await this.actionShowModal({
        id: "ModalMyPurcharses",
        purchase
      });
    },
    async load() {
      this.purchases = await this.actionGetPurchases();
    },
    isStateInvalid(purchase) {
      return !['paid', 'completed', 'ended'].includes(purchase.status)
    },
    getTypeLabel(purchase) {
      return this.$t(`minha_conta.purchases.transaction_type.${purchase.transaction_type}`)
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style scoped lang="scss">
.card {
  background-color: var(--card-background-color);
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  flex-wrap: wrap;
  cursor: pointer;
}

.title {
  min-width: 16rem;
}

.tags {
  margin-right: 2rem;
}

.indicator {
  position: absolute;
  right: 1rem;
  top: 1.4rem;
}
</style>
