<template>
  <div id="sidebar-modal" class="sidebar-modal flex flex-row">
    <div class="sidebar-backdrop flex-1 fade-in" @click="$emit('close')"></div>
    <slot>
      <div :class="[
        'sidebar-container flex flex-col slide-in-right',
        { mobile: isMobile }
      ]">
        <slot name="close">
          <a class="flex flex-row ml-auto cursor-pointer" @click="$emit('close')">
            <IconSax name="close-circle" />
          </a>
        </slot>
        <slot name="header">
          <div v-if="title" class="flex flex-col mb-4">
            <TextPlay :text="title" color="var(--title-color)" weight="bold" size="xl" />
            <TextPlay :text="subtitle" size="sm" color="var(--text-color)" />
          </div>
        </slot>
        <slot name="content"></slot>
      </div>
    </slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isMobile: "layout/getIsMobile",
    }),
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@mixin animation-slide-x($x: 30rem, $time: 0.5s) {
  animation: slide-x $time ease-in-out both;

  @keyframes slide-x {
    0% {
      transform: translateX($x);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

@mixin fade-in($time: 0.5s) {
  animation: fade-in $time ease-in-out both;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.sidebar-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
}

.sidebar-backdrop {
  @include fade-in;
  background: var(--fundomodal);
}

.sidebar-container {
  @include animation-slide-x(30rem);
  background-color: var(--backgroundcolor);
  padding: 2.5rem;
  min-width: 30rem;

  &.mobile {
    padding: 1rem;
    min-width: 100vw;
    width: 100vw;
  }
}

.p-4 {
  padding: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-4 {
  margin-bottom: 1rem;
}
</style>
