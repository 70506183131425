<template>
  <Modal
      :visible="true"
      id="modal-conditional-links-confirm"
      size="lg"
      :title="$t('members.modal_exit_confirmation.title')"
      @close="$emit('close')">
    <p class="txt01">{{$t('members.modal_exit_confirmation.txt01')}}</p>

    <template slot="footer">
      <div class="d-flex flex-column gap-1">
        <ButtonPlay
            type="normal"
            @click="restore"
            :text="$t('members.modal_exit_confirmation.buttons.save')"
            loader="spinner"
        />
        <ButtonPlay
            type="outline"
            bgColor="transparent"
            bgColorOnHover="transparent"
            color="var(--maincolor)"
            @click="$emit('close')"
            :text="$t('members.modal_exit_confirmation.buttons.exit')"
        />
      </div>
    </template>
  </Modal>
</template>
<script>
import {mapActions} from "vuex";

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    ...mapActions({
      actionShowModal: 'app/actionShowModal',
    }),

    restore() {
      this.$emit('close')
      this.actionShowModal({
        id: "ModalConditionalLinks",
        history: this.data,
      });
    }
  }
};
</script>
<style lang="scss">
#modal-conditional-links-confirm {
  .modal-dialog {
    width: 485px;
    height: 322px;
  }

  .modal-body .h-full {
    height: 100%;
  }

  .container_wrap {
    display: none;
  }

  .headerTitle {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 24px;
    line-height: 26.4px;
  }

  .txt01 {
    color: var(--neutral-gray-300);
  }
}

[data-theme="light"] {
  #modal-conditional-links-confirm {
    .headerTitle {
      color: var(--neutral-gray-900);
    }

    .txt01 {
      color: var(--neutral-gray-900);
    }
  }
}
</style>
