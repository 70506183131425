<template>
    <div v-if="shouldShow()" class="continueLearningNav">
        <div class="continue-learning-top">
            <TextPlay size="xl" weight="bold" color="var(--fontcolor)" :text="$t('learning.title')"/>
            <CommonControlsCarousel
                :disableNext="isEnd || isLocked"
                :disablePrev="isBeginning || isLocked"
                @prev="prev(uniqueId)"
                @next="next(uniqueId)"
            />
        </div>

        <Swiper :ref="`${uniqueId}`" :options="swiperOption">
            <template>
                <SwiperSlide  v-for="item in modules" :key="item.id">
                    <div class="continue-learning-cards">
                        <div class="continue-learning-card">

                            <div class="continue-learning-card__image" @click="openCourse(item)">
                                <img v-if="item.image" :src="item.image">
                                <div v-else class="continue-learning-card__image"></div>

                                <div class="continue-learning-card__icon">
                                    <IconSax v-if="item.icon != 'play'" :name="item.icon" color="white" type="bold" size="20px" />
                                    <template v-else>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="white">
                                        <circle cx="20" cy="20" r="20"/>
                                        <path d="M26.1992 18.4691C27.5326 19.2389 27.5326 21.1634 26.1992 21.9332L18.6992 26.2634C17.3659 27.0332 15.6992 26.0709 15.6992 24.5313L15.6992 15.871C15.6992 14.3314 17.3659 13.3692 18.6992 14.139L26.1992 18.4691Z" fill="white"/>
                                        </svg>
                                    </template>
                                </div>

                            </div>

                            <div class="continue-learning-card__description">
                                <div class="continue-learning-card__description__titles">
                                    <TextPlay :title="item.course" size="sm" color="var(--text-color)" :text="$t('learning.title_course', { title: item.course })"/>
                                    <TextPlay :title="item.module" size="sm" color="var(--text-color)" :text="$t('learning.title_module', { title: item.module })"/>
                                    <TextPlay :title="item.title" color="var(--fontcolor)" size="lg" weight="bold" :text="item.title" />
                                </div>

                                <div class="continue-learning-card__nav-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <TextPlay class="mb-2" size="xs" color="var(--text-color)" :text="$t('learning.course_progress', { percentage: item.progress })" />
                                        <IconSax name="medal-star" size="16px" primary />
                                    </div>
                                    <ProgressBar :percentage="item.progress" />
                                </div>

                                <div class="continue-learning-card__action">
                                    <ButtonPlay :text="$t('learning.button_class')" iconRight="arrow-right-1" size="sm" type="normal" @click="openCourse(item)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </template>
        </Swiper>
    </div>
</template>

<script>  
const serviceCourse = CourseService.build();
import CourseService from "@/services/resources/CourseService";
import { mapActions, mapGetters } from "vuex";
import { textLimit } from "@/utils/strings";

export default {
    components: {
        CommonControlsCarousel: require("@/components/common/carousel/ControlsCarousel.vue").default,
        ProgressBar: () => import("@/components/common/progressBar/ProgressBar.vue")
    },
    computed: {
        ...mapGetters({
            getSettings: "config/getSettings",
            getMe: "auth/getMe"
        }),
    },
    data() {
        return {
            uniqueId: null,
            isLocked: false,
			isBeginning: true,
			isEnd: false,
            limit_character: 35,
            swiperOption: {
                direction: "horizontal",
                loop: false,
                height: 60,
                spaceBetween: 20,
                slidesPerView: 1,
                navigation: true,
                centeredSlides: false,
                resizeReInit: true,
                watchOverflow: true,
                on: {
					lock: () => {
						this.isLocked = true;
					},
					unlock: () => {
						this.isLocked = false;
					},
					progress: progress => {
						if (progress === 1) {
							this.isBeginning = false;
							this.isEnd = true;
						} else if (progress <= 0) {
							this.isBeginning = true;
							this.isEnd = false;
						} else {
							this.isBeginning = false;
							this.isEnd = false;
						}
					},
				},
            },
            modules: []
        };
    },
    mounted() {
        this.uniqueId = this.createUniqueId();
        serviceCourse
        .read(
            this.getMe.member.id + "/whereStopped"
        ).then((data) => {
            this.setModules(data)
        })
    },
    methods: {
        ...mapActions({
            actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
        }),
        textLimit,
        setModules(data){
            if(data.length > 0){
                let courses = data.filter(course => course.modules?.some(module => module.currentModuleLessons !== null));
                var result = [];
                courses.map((course) => {
                    course.modules.map((module) => {
                        if(module.currentModuleLessons){
                            let image = (module.currentModuleLessons.custom_thumb) ? module.currentModuleLessons.custom_thumb.cdn_url : course.cover.cdn_url;
                            if(module.lessons_completed_count > 0){
                                let url = `/curso/${course.id}/modulo/${module.id}/aula/${module.currentModuleLessons.id}`;
                                result.push({
                                    id: course.id,
                                    title: module.currentModuleLessons.title,
                                    course: course.title,
                                    module: module.title,
                                    image: image,
                                    icon: this.getLessonIcon(module.currentModuleLessons.mediaType),
                                    progress: course.percentage_complete,
                                    url: url,
                                    isCourseLiberated: course?.is_liberated,
                                    courseLiberationDate: course?.liberated_at,
                                    courseRuleLiberation: course?.rule_liberation,
                                    isModuleLiberated: module.is_liberated,
                                    moduleLiberationDate: module.liberated_at,
                                    moduleRuleLiberation: module.rule_liberation,
                                    isClassLiberated: module.currentModuleLessons.is_liberated,
                                    classLiberationDate: module.currentModuleLessons.liberated_at,
                                    classRuleLiberation: module.currentModuleLessons.rule_liberation,
                                });
                            }
                        }
                    });
                })

                let filtered = this.filterById(result);

                if(result.length > 0 && filtered){
                    filtered.map(filter =>  this.modules.push(filter));
                }
            }
        },
        filterById(datas) {
            const ids = {};
            return datas.filter(data => !ids[data.id] && (ids[data.id] = true));
        },
        createUniqueId() {
            const timestamp = new Date().getTime();
            const randomNumber = Math.floor(Math.random() * 10000);
            const uniqueId = `${timestamp}_${randomNumber}`;
            return uniqueId;
        },
        prev(name) {
            this.$refs[name].$swiper.slidePrev();
        },
        next(name) {
            this.$refs[name].$swiper.slideNext();
        },
        shouldShow(){
            const { learning_progression } = this.getSettings
            return this.modules.length > 0 && learning_progression && this.getMe.member.role === "student";
        },
        openCourse(item){
            let data;
            if(item.isCourseLiberated === false){
                data = {
                    rule: item.courseRuleLiberation,
                    date: item.courseLiberationDate,
                    type: 1,
                };
            }
            if(item.isModuleLiberated === false){
                data = {
                    rule: item.moduleRuleLiberation,
                    date: item.moduleLiberationDate,
                    type: 4,
                };
            }
            if(item.isClassLiberated === false){
                data = {
                    rule: item.classRuleLiberation,
                    date: item.classLiberationDate,
                    type: 7,
                };
            }

            if(item. isCourseLiberated === false || item.isModuleLiberated === false || item.isClassLiberated === false){
                this.actionShowModalInfoCourse({
                    active: true,
                    data,
                });
                return;
            }
            this.$router.push(item.url);
        },
        getLessonIcon(mediaType) {        
            const mediaIconMap = {
                "youtube": "play",
                "vimeo": "play",
                "panda": "play",
                "voomptube": "play",
                "audio": "microphone",
                "text": "clipboard-text",
                "quiz": "clipboard-tick",
            };

            return mediaIconMap[mediaType] || "play";
        }
    }
}
</script>


<style lang="scss" scoped>
[data-theme="dark"] {
    .continue-learning-card{
        background-color: var(--neutral-gray-800);
    }

    .continue-learning-card__image{
        background-color: var(--neutral-black);
    }
}

.continue-learning-card {
    display: flex !important;
    flex-direction: row !important;
    width: 100%;
    height: 248px;
    background-color: var(--neutral-gray-80);
    padding: 24px;
    gap: 32px;
    border-radius: 8px;

    &__nav-content {
        max-width: 332px;
    }

    &__icon {
        background-color: var(--maincolor);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 9999999;
        transform: translate(-50%, -50%);
    }

    &__description {
        display: flex;
        flex-direction: column;
        width: 50%;
        border-radius: 8px;
        gap: 24px;

        &__titles {
            display: flex;
            flex-direction: column;
            gap: 4px;

            p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &__image {
        position: relative;
        display: flex;
        width: 50%;
        align-content: center;
        justify-content: center;
        background-color: #000000;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        height: 200px;
        cursor: pointer;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            z-index: 2;
            border-radius: 8px;
            filter: brightness(0.6)
        }
    }

    &__icon i {
        background: var(--maincolor);
        border-radius: 50%;
        padding: 1rem;
    }

    &__icon circle {
        fill: var(--maincolor);
    }

    &__icon i::before {
        font-size: 25px;
        color: #fff;
    }
}

.continueLearningNav {
    position: relative;
    width: 100%;
    height: 340px;
    padding-top: 16px;
    border-radius: 8px;
    display: flex;
    margin-bottom: 3em;
    flex-direction: column;
    overflow: hidden;
}

.continue-learning-top{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}

.continue-learning-cards{
    display: flex;
    gap: 35px;
}

.swiper-slide {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.swiper-wrapper {
    display: flex;
    gap: 24px;
}

.swiper-container{
    margin: 0;
}

@media screen and (max-width: 768px) {
    .continue-learning-card {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
        height: auto;
        gap: 10px;

        &__image {
            width: 100%;
            height: 120px;
        }

        &__description{
            width: 100%;
        }

        &__icon{
            top: 50;
            left: 50%;
        }

        &__image img, &__image{
            height: 130px;
        }
    }

    .continueLearningNav {
        height: 450px;
    }
}
</style>