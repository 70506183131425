export class ConditionalLinkAdapter {
    index = null
    label= null
    url= null
    icon= null
    newtab= false
    conditional= false
    type= null
    signature_ids= []
    course_class_ids= []

    constructor(props = {}) {
        Object.assign(this, props)
    }
}