<template>
    <div class="select-play">
        <div class="select-play__selected" @click="open = !open">
            {{ selected }} <IconSax size="14px" type="outline" name="arrow-down" />
        </div>
        <div class="select-play__items" :class="{ hide: !open }">
            <TextPlay
                class="select-play__items--title"
                :text="title"
                v-if="title"
            />
            <div
                class="select-play__items__item"
                v-for="(option, i) of options"
                :key="i"
                @click="
                    selected = option.label;
                    open = false;
                    $emit('input', option.id);
                "
            >
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script>
import TextPlay from "@/components/common/TextPlay.vue";

export default {
    components: {
        TextPlay,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selected: this.default
                ? this.default
                : this.options.length > 0
                ? this.options[0].label
                : null,
            open: false,
        };
    },
    mounted() {
        this.$emit("input", this.selected);
    },
};
</script>

<style lang="scss" scoped>
.select-play {
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;

    &.hide {
        display: none;
    }

    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
    }

    &__selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        border-radius: 6px;
        border: 1px solid var(--bordercolor);
        color: var(--fontcolor);
        cursor: pointer;
        user-select: none;
        padding: 12px 16px;
        width: 100%;
    }

    &__items {
        position: absolute;
        z-index: 1;
        overflow: hidden;
        border-radius: 16px;
        padding: 24px 24px 31px 24px;
        left: 0;
        right: 0;
        top: 60px;

        [data-theme="light"] & {
            background-color: var(--neutral-gray-80);
        }

        [data-theme="dark"] & {
            background-color: var(--neutral-gray-800-1);
        }

        &--title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 40px;
            border: none;
            color: var(--fontcolor);
        }

        &__item {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            color: var(--fontcolor);
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--bordercolor);

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
</style>
