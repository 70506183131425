export const isBool = val => {
  return [true, false, 'true', 'false', '1', '0', 1, 0].includes(val)
}

export const boolVal = val => {
  if (!isBool(val)) {
    return false
  }

  return [true, 'true', '1', 1].includes(val);
}