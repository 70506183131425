<template>
  <Layout>
    <TourGuide ref="tourGuide" :steps="studentSteps" />
    <ModalFirstAccess v-if="showModalFirstAccess" @closeModalFirstAccess="closeModalFirstAccess"  />
    <ModalTermsOfUse v-if="showModalTermsOfUse" @closeModalTermsOfUse="closeModalTermsOfUse" />
    <!-- CAROUSEL DE BANNERS -->
    <keep-alive>
      <section id="banner-header" class="play-bannerHeader">
        <template>
          <transition>
            <header class="play-bannerCarousel">
              <BannerCarousel
                :data="getHomeData && getHomeData?.banners_top"
                :loading="getIsLoading"
                :emptyState="true"
              />
            </header>
          </transition>
        </template>
      </section>
    </keep-alive>

    <!-- CONTROLE DO ADMINISTRADOR -->
    <keep-alive>
      <template v-if="!getIsMobile && getIsAdmin">
        <section class="play-adminControls" data-testid="admin-controls">
          <AdminBar />
        </section>
      </template>
    </keep-alive>

    <!-- HOME -->
    <div id="home" data-testid="home" class="play">
      <!-- HISTÓRICOS DE VIDEOS -->
      <!-- <transition>
        <template>
          <section class="play-recentViews mb-4" data-testid="recents-view">
            <RecentViews
              :data="getHomeData && getHomeData.courses"
              :hideDisabledCourses="getHideDisabledCourses"
              :loading="getIsLoading"
            />
          </section>
        </template>
      </transition> -->

      <template>
        <ContinueLearning />
      </template>

      <!-- LISTA DE CATEGORIAS -->
      <template v-if="getEnabledCategory">
        <section
          class="play-categories"
          data-testid="categories"
        >
          <div
            class="categories_title"
            :class="{ 'skeleton skeleton-dark': getIsLoading }"
          >
            Escolha uma categoria
          </div>
          <CategoriesNav
            :data="getHomeData?.categories"
            :loading="getIsLoading"
          />
        </section>
      </template>

      <main class="play-videos">
        <!-- VISUALIZAÇÃO POR CURSOS -->
        <template v-if="getCategoryViewMode">
          <CourseViewMode
            :data="getHomeData"
            :hideDisabledCourses="getHideDisabledCourses"
            :loading="getIsLoading"
          >
            <template
              v-slot:normal="{ data }"
              v-if="!getModeCarousel || getRearrangeCourse"
            >
              <CourseNormal
                v-if="data"
                :showRating="getShowRating"
                :data="data"
                :lastPage="getHomeData?.courses_last_page"
                :loading="getIsLoading"
                :viewType="home_vertical"
              />
            </template>
            <template v-slot:normal="{ data }" v-else>
              <CourseCarousel
                v-if="data"
                :loading="getIsLoading"
                :viewType="home_vertical"
              />
            </template>
          </CourseViewMode>
        </template>

        <!-- VISUALIZAÇÃO POR MÓDULOS -->
        <template v-if="getModuleViewMode">
          <template>
            <ModuleViewMode
              :data="getHomeData && getHomeData.categories"
              :hideDisabledCourses="getHideDisabledCourses"
              :loading="getIsLoading"
            >
              <!-- NORMAL -->
              <template
                v-slot:normal="{ data }"
                v-if="!getModeCarousel && !getIsMobile"
              >
                <ModuleNormal
                  :showRating="getShowRating"
                  :data="data"
                  :loading="getIsLoading"
                  :viewMode="'module'"
                  :viewType="home_vertical"
                />
              </template>
              <!-- CAROUSEL -->
              <template
                v-slot:carousel="{ data }"
                v-if="getModeCarousel || getIsMobile"
              >
                <ModuleCarousel
                  :showRating="getShowRating"
                  :data="data"
                  :viewMode="'module'"
                  :viewType="home_vertical"
                  :loading="getIsLoading"
                />
              </template>
            </ModuleViewMode>
          </template>
        </template>

        <!-- CAROUSEL DE BANNERS -->
        <section id="banner-bottom" class="play-bannerBottom">
          <template>
            <transition>
              <header class="play-bannerCarousel">
                <BannerCarousel
                  :data="getHomeData && getHomeData?.banners_bottom"
                  :loading="getIsLoading"
                />
              </header>
            </transition>
          </template>
        </section>
      </main>
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createStudentSteps } from "@/utils/studentSteps";

export default {
  components: {
    Layout: () =>
      import(/* webpackChunkName: "LayoutHomePlay" */ "@/layout/Layout.vue"),
    BannerCarousel: () =>
      import(
        /* webpackChunkName: "BannerCarousel" */ "@/components/common/carousel/BannerCarousel.vue"
      ),
    /* View By Course */
    CourseViewMode: () =>
      import(
        /* webpackChunkName: "CourseViewMode" */ "@/components/pages/home/viewByCourse/CourseViewMode.vue"
      ),
    CourseNormal: () =>
      import(
        /* webpackChunkName: "CourseNormal" */ "@/components/pages/home/viewByCourse/Normal.vue"
      ),
    CourseCarousel: () =>
      import(
        /* webpackChunkName: "CourseCarousel" */ "@/components/pages/home/viewByCourse/Carousel.vue"
      ),
    /* View By Module */
    ModuleViewMode: () =>
      import(
        /* webpackChunkName: "ModuleViewMode" */ "@/components/pages/home/viewByModule/ModuleViewMode.vue"
      ),
    ModuleNormal: () =>
      import(
        /* webpackChunkName: "ModuleNormal" */ "@/components/pages/home/viewByModule/Normal.vue"
      ),
    ModuleCarousel: () =>
      import(
        /* webpackChunkName: "ModuleCarousel" */ "@/components/pages/home/viewByModule/Carousel.vue"
      ),
    /* Admin */
    AdminBar: () =>
      import(
        /* webpackChunkName: "AdminBar" */ "@/components/pages/home/admin/AdminBar.vue"
      ),
    // RecentViews: () => import("@/components/pages/home/RecentViews.vue"),
    CategoriesNav: () =>
      import(
        /* webpackChunkName: "CategoriesNav" */ "@/components/common/CategoriesNav.vue"
      ),
    
    ModalTermsOfUse: () => import("@/components/ModalTermsOfUse.vue"),
    ContinueLearning: () => import("@/components/common/ContinueLearningNav.vue"),
    ModalFirstAccess: () => import("@/components/ModalFirstAccess.vue"),
    TourGuide: () => import("@/components/TourGuide.vue")
  },
  data() {
    return {
      showModalTermsOfUse: true,
      showModalFirstAccess: true,
      modalContent: {
        cover: {},
        title: null,
        description: null,
        button: null,
        url: null
      },
      config: {},
      home_vertical: null,
      studentSteps: createStudentSteps(this, !this.getIsMobile),
    };
  },
  async mounted() {
    document.body.style.backgroundImage = "";

    this.getConfig();

    const key = await this.actionGetMetaSiteScoped("first_access_optout");
    const _config = await this.actionUserFetchMetas({keys: [key]})

    this.currentSiteId = this.getMe.current.currentSite.id;
    const first_access_enabled = this.getSettings['first_access_enabled'];

    if(_config[`site_${this.currentSiteId}__first_access_optout`] === 'confirmed' || !first_access_enabled){
      this.startTourGuide();
    }
  },
  methods: {
    ...mapActions({
      actionShowModalBuyCourse: "home/actionShowModalBuyCourse",
      actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
      actionFetchMetas: "config/actionFetchMetas",
      actionUserFetchMetas: "user/actionFetchMetas",
      actionGetMetaSiteScoped: "user/actionGetMetaSiteScoped",
      actionShowModalFirstAccess: "home/actionShowModalFirstAccess",
    }),

    async closeModalTermsOfUse() {
      this.showModalTermsOfUse = false;

      if (this.showModalFirstAccess) {
        
        const first_access_enabled = this.getSettings['first_access_enabled'];
  
        const key = await this.actionGetMetaSiteScoped("first_access_optout");

        const _config = await this.actionUserFetchMetas({keys: [key]})

        this.currentSiteId = this.getMe.current.currentSite.id;

        if(_config[`site_${this.currentSiteId}__first_access_optout`] === 'confirmed' || !first_access_enabled){
          this.startTourGuide();
        }
			}
    },

    closeModalFirstAccess() {
      this.showModalFirstAccess = false;

      const tourGuide = this.$refs.tourGuide;

      let hasModalGameficationNewMedal = false;

      if(this.getModals){
        this.getModals.forEach(modal => {
          if(modal.id === "ModalGameficationNewMedal"){
            hasModalGameficationNewMedal = true;
          }
        });
      }

      if(hasModalGameficationNewMedal){
        return;
      }
      tourGuide.startTour();
    },

    openModalBuyCourse(item) {
      this.actionShowModalBuyCourse({
        active: true,
        data: item,
      });
    },

    async getConfig() {
      this.config = await this.actionFetchMetas({
       keys: ["home_vertical","view_course"]
      })

      if(this.config && this.config.home_vertical !== undefined){
        if(this.config.home_vertical === false || this.config.home_vertical === 'off' && !this.config.view_course){
          this.home_vertical = 'horizontal_cover';
        }else if(this.config.home_vertical === true || this.config.home_vertical === 'on'){
          this.home_vertical = 'film_cover';
        }else{
          this.home_vertical = this.config.home_vertical
        }
      }
    },

    async openModal(item) {
      this.actionShowModalInfoCourse({
        active: true,
        date: item,
      });
    },

    async startTourGuide() {
        setTimeout(() => {
          const tourGuide = this.$refs.tourGuide;
          let hasModalGameficationNewMedal = false;

          if(this.getModals){
            this.getModals.forEach(modal => {
              if(modal.id === "ModalGameficationNewMedal"){
                hasModalGameficationNewMedal = true;
              }
            });
          }
          if(hasModalGameficationNewMedal){
            return;
          }

          if (tourGuide && tourGuide.startTour) {
            tourGuide.startTour();
          } else {
            console.error("tourGuide ou método startTour não está disponível.", {
              tourGuide,
              hasStartTour: tourGuide?.startTour
            });
          }
        }, 800);
    }
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
      getSettings: "config/getSettings",
      getModuleViewMode: "config/getModuleViewMode",
      getEnabledCategory: "config/getEnabledCategory",
      getCategoryViewMode: "config/getCategoryViewMode",
      getMovieCoverMode: "config/getMovieCoverMode",
      getHideDisabledCourses: "config/getHideDisabledCourses",
      getIsMobile: "layout/getIsMobile",
      getModeCarousel: "config/getModeCarousel",
      getRearrangeCourse: "home/getRearrangeCourse",
      getShowRating: "config/getShowRating",
      getIsLoading: "config/getIsLoading",
      getAllowRequest: "config/getAllowRequest",
      getHomeData: "home/getHomeData",
      getAllCategories: "home/getAllCategories",
      getModalInfoCourse: "home/getModalInfoCourse",
      getModalBuyCourse: "home/getModalBuyCourse",
      getIsAdmin: "config/getIsAdmin",
      getUserMetas: "user/getMetas",
      getCurrentSite: "user/currentSite",
    }),
  },
};
</script>

<style lang="scss" scoped>
.categories_title {
  color: var(--fontcolor);
  @extend .body-semibold-14;
}

.play {
  padding: 16px;

  &-footer {
    height: 70px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 769px) {
    padding: var(--spacing-24) var(--spacing-36);
  }
}

.step-box {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background: #f9f9f9;
}

</style>
