<template>
    <div class="standard-page">
        <template v-if="getIsLoading">
            <div>
                <div
                    class="skeleton skeleton__courses-header"
                    style="margin-bottom: 24px"
                ></div>
                <div
                    class="skeleton skeleton__courses-datagrid"
                    style="margin-bottom: 12px"
                ></div>
                <div
                    class="skeleton skeleton__courses-datagrid"
                    style="margin-bottom: 12px"
                ></div>
                <div
                    class="skeleton skeleton__courses-datagrid"
                    style="margin-bottom: 12px"
                ></div>
                <div
                    class="skeleton skeleton__courses-datagrid"
                    style="margin-bottom: 12px"
                ></div>
                <div
                    class="skeleton skeleton__courses-datagrid"
                    style="margin-bottom: 12px"
                ></div>
            </div>
        </template>

        <template v-else>
            <div class="standard-page__header">
                <TitlePage :title="$t('my_courses.page_title')">
                    <IconSax name="video-play" />
                </TitlePage>

                <ButtonPlay
                    @click="openOtherPlatformsMenu()"
                    :text="$t('my_courses.other_plataforms.btn_title')"
                    type="static"
                    icon="global"
                    :icon-right="getIsMobile ? 'arrow-down' : ''"
                    :is-mobile="getIsMobile"
                />

                <div
                    class="mobile__other-platforms-list"
                    v-show="getIsMobile && isOtherPlatformsMobileListOpen"
                >
                    <div class="mobile__other-platforms-list--title">
                        {{ $t("my_courses.other_plataforms.mobile.title") }}
                    </div>
                    <div
                        class="mobile__other-platforms-list--content"
                        v-if="otherPlatforms.length > 0"
                    >
                        <div
                            class="mobile__other-platforms-list--content--item"
                            v-for="platform in otherPlatforms"
                            :key="platform.id"
                        >
                            <TextPlay
                                @click="redirectTo(platform)"
                                size="sm"
                                color="var(--fontcolor)"
                                weight="semibold"
                                :text="platform.name"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="standard-page__content"
                v-for="(course, category, index) in courses"
                :key="index"
            >
                <CoursesDatagridComponent
                    :category-title="category"
                    :courses-by-category="course"
                />
            </div>

            <infinite-loading @infinite="infiniteHandler" v-if="!getIsLoading">
                <div slot="spinner">
                    <loading size="3rem" color="var(--maincolor)" />
                </div>
                <div slot="no-more">
                    <p class="body-regular-14 color-base text-left">
                        {{
                            $t(
                                "my_courses.courses_table.infinite_loading.no_more_courses"
                            )
                        }}
                    </p>
                </div>
                <div slot="no-results">
                    <p class="body-regular-14 color-base text-left">
                        {{
                            $t(
                                "my_courses.courses_table.infinite_loading.courses_not_found"
                            )
                        }}
                    </p>
                </div>
            </infinite-loading>

            <div class="drawer-sidebar" v-if="isOtherPlatformsListOpen">
                <div class="other-platforms-sidebar animationMenuMobile3">
                    <div class="other-platforms-sidebar__header">
                        <div
                            class="other-platforms-sidebar__header--close-button"
                            @click="closeOtherPlatformsMenu"
                        >
                            <IconSax
                                color="var(--fontcolor)"
                                name="close-circle"
                            />
                        </div>

                        <div class="other-platforms-sidebar__header--title">
                            <TextPlay
                                size="sm"
                                color="var(--fontcolor)"
                                weight="semibold"
                                :text="$t('my_courses.other_plataforms.title')"
                            />
                        </div>
                    </div>

                    <div
                        class="other-platforms-sidebar__content"
                        v-if="otherPlatforms.length > 0"
                    >
                        <div
                            class="other-platforms-sidebar__content__item"
                            v-for="platform in otherPlatforms"
                            :key="platform.id"
                        >
                            <div
                                class="other-platforms-sidebar__content__item--platform-title"
                            >
                                <TextPlay
                                    size="sm"
                                    color="var(--fontcolor)"
                                    weight="semibold"
                                    :text="platform.name"
                                />
                            </div>
                            <div
                                class="other-platforms-sidebar__content__item--platform-link"
                                @click="redirectTo(platform)"
                            >
                                {{
                                    $t(
                                        "my_courses.other_plataforms.access_platform"
                                    )
                                }}

                                <IconSax
                                    size="16px"
                                    primary
                                    type="outline"
                                    name="export-3"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ButtonPlay from "@/components/common/ButtonPlay.vue";
import Loading from "@/components/common/Loading.vue";
import TextPlay from "@/components/common/TextPlay.vue";
import TitlePage from "@/components/common/TitlePage.vue";
import InfiniteLoading from "vue-infinite-loading";
import CoursesDatagridComponent from "./CoursesDatagridComponent.vue";

export default {
    name: "MyCoursesComponent",

    components: {
        TitlePage,
        ButtonPlay,
        TextPlay,
        CoursesDatagridComponent,
        InfiniteLoading,
        Loading,
    },

    data() {
        return {
            isOtherPlatformsListOpen: false,
            isOtherPlatformsMobileListOpen: false,
            client: {
                width: 0,
                height: 0,
            },
            currentSite: 0,
            page: 1,
        };
    },

    computed: {
        ...mapGetters({
            getMe: "auth/getMe",
            getListCourses: "myCourses/getListCourses",
            getIsLoading: "config/getIsLoading",
            getIsMobile: "layout/getIsMobile",
        }),

        courses() {
            return this.getListCourses?.courses || {};
        },

        otherPlatforms() {
            let currentSite = this.getMe?.current.currentSite;

            let platforms = this.getMe.member.sites.filter(
                (site) => site.id !== currentSite.id
            );

            return platforms;
        },
    },

    methods: {
        ...mapActions({
            actionFetchAllCoursesData: "myCourses/actionFetchAllCoursesData",
        }),

        redirectTo(platform) {
            let payload = this.getAuthSiteBySiteId(platform.id);
            let url = `https://${payload.subdomain}.voompplay.${process.env.VUE_URL_DOMAIN}`;
            window.open(url, "_blank");
        },

        getAuthSiteBySiteId(siteId) {
            return this.getMe.member.sites.find((site) => site.id === siteId);
        },

        getCoursesByCategory(categoryId) {
            return this.courses.filter(
                (course) => course.category.id === categoryId
            );
        },

        openOtherPlatformsMenu() {
            this.getIsMobile === true
                ? (this.isOtherPlatformsMobileListOpen =
                      !this.isOtherPlatformsMobileListOpen)
                : (this.isOtherPlatformsListOpen =
                      !this.isOtherPlatformsListOpen);
        },

        closeOtherPlatformsMenu() {
            this.getIsMobile === true
                ? (this.isOtherPlatformsMobileListOpen = false)
                : (this.isOtherPlatformsListOpen = false);
        },

        infiniteHandler($state) {
            const currentPage = this.getListCourses?.meta?.current_page || 0;
            const lastPage = this.getListCourses?.meta?.last_page || 0;

            if (currentPage >= lastPage) {
                $state.complete();
                return;
            }

            this.page = currentPage + 1;
            this.actionFetchAllCoursesData({ page: this.page })
                .then(() => {
                    $state.loaded();
                })
                .catch((e) => {
                    $state.error();
                });
        },
    },

    async mounted() {
        await this.actionFetchAllCoursesData({ page: 1 });
    },
};
</script>

<style lang="scss" scoped>
.infinite-loading-container {
    text-align: center !important;
    padding: 5rem 0 !important;
}
.standard-page {
    padding: 64px 40px 100px 40px;
    height: 100vh;

    &:has(.drawer-sidebar) {
        overflow: hidden;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-bottom: 24px;

        button {
            color: var(--fontcolor) !important;

            ::v-deep svg {
                path {
                    fill: var(--fontcolor) !important;
                }
            }

            [data-theme="light"] & {
                background-color: var(--neutral-gray-100) !important;
            }
            [data-theme="dark"] & {
                background-color: var(--neutral-gray-800) !important;
            }
        }
    }

    .mobile {
        &__sorting {
            display: none;
        }
    }
}

.drawer-sidebar {
    position: fixed;
    background: var(--fundomodal);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999999;

    .other-platforms-sidebar {
        z-index: 99999999;
        position: absolute;
        height: 100%;
        width: 520px;
        top: 0px;
        background: var(--backgroundcolor);
        right: 0px;
        border-radius: 3px 0px 0px 3px;
        padding: 40px;
        overflow: auto;

        &__header {
            margin-bottom: 32px;

            &--close-button {
                cursor: pointer;
                position: absolute;
                top: 16px;
                right: 16px;
            }

            &--title {
                p {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            &__item {
                display: flex;
                padding: 16px 0px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                [data-theme="light"] & {
                    border-top: 1px solid var(--neutral-gray-100);
                }
                [data-theme="dark"] & {
                    border-top: 1px solid var(--neutral-gray-700);
                }

                &--platform-title {
                    display: flex;
                    align-items: flex-start;
                    flex: 1 0 0;

                    p {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }

                &--platform-link {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;

                    color: var(--maincolor);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                }
            }

            &:last-child {
                [data-theme="light"] & {
                    border-bottom: 1px solid var(--neutral-gray-100);
                }
                [data-theme="dark"] & {
                    border-bottom: 1px solid var(--neutral-gray-700);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .standard-page {
        padding: 32px 16px;

        .mobile {
            &__sorting {
                width: 100%;
                display: block;
            }
        }
    }

    // Other Platforms Mobile selector
    .mobile__other-platforms-list {
        width: calc(100% - 30px);
        border-radius: 16px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 24px 24px 31px 24px;
        margin-top: 50px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        z-index: 9999999;
        position: absolute;

        &--title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }

        &--content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;

            &--item {
                width: 100%;
                padding-bottom: 24px;

                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                cursor: pointer;
            }
        }

        [data-theme="light"] & {
            background: var(--neutral-gray-80);

            &--content {
                &--item {
                    border-bottom: 1px solid var(--neutral-gray-200);
                }
            }
        }

        [data-theme="dark"] & {
            color: var(--neutral-white);
            background: var(--neutral-gray-800-1);

            &--content {
                &--item {
                    border-bottom: 1px solid var(--neutral-gray-700);
                }
            }
        }
    }
}
</style>
