var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[(_vm.coursesByCategory)?_c('div',{staticClass:"courses-datagrid",attrs:{"name":_vm.categoryTitle}},[_c('TextPlay',{staticClass:"courses-datagrid__category-title",attrs:{"text":_vm.categoryTitle}}),_c('div',{staticClass:"courses-datagrid__header"},[_c('div',{staticClass:"courses-datagrid__header__row"},[_c('div',{staticClass:"courses-datagrid__header__row__column",attrs:{"name":"course-name-column"},on:{"click":function($event){return _vm.sortCoursesBy(
                            'title',
                            _vm.sortOptions.titleCurrentOrientation
                        )}}},[_c('IconSax',{attrs:{"size":"14px","type":"bold","name":"arrow-3"}}),_c('div',{staticClass:"courses-datagrid__header__row__column--title"},[_vm._v(" "+_vm._s(_vm.$t("my_courses.courses_table.columns.name"))+" ")])],1),_c('div',{staticClass:"courses-datagrid__header__row__column",attrs:{"name":"progress-column"},on:{"click":function($event){return _vm.sortCoursesBy(
                            'percentage_complete',
                            _vm.sortOptions.progressCurrentOrientation
                        )}}},[_c('IconSax',{attrs:{"size":"14px","type":"bold","name":"arrow-3"}}),_c('div',{staticClass:"courses-datagrid__header__row__column--title"},[_vm._v(" "+_vm._s(_vm.$t("my_courses.courses_table.columns.progress"))+" ")])],1),_c('div',{staticClass:"courses-datagrid__header__row__column--actions"})]),_c('div',{staticClass:"courses-datagrid__header--mobile"},[_c('SimpleSelectPlay',{attrs:{"title":_vm.$t('my_courses.courses_table.mobile.title'),"options":_vm.sortOptions.selectOptions},on:{"input":(id) => _vm.sortCoursesBy('', '', id)}})],1)]),_c('div',{staticClass:"courses-datagrid__body"},_vm._l((_vm.courses),function(course){return _c('CourseDatagridRowComponent',{key:course.id,attrs:{"course":course}})}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }