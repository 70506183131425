<template>
	<div
		class="modalUnavailable"
		data-testid="modal-unavailable"
	>
		<div class="modal-unavailable">
			<div class="modal-unavailable__header">
				<IconSax
					@click="closeAction"
					name="close-circle"
					size="24px"
				/>
			</div>

			<div class="modal-unavailable__body">
				<div class="modal-unavailable__body__icon">
					<IconSax
						size="20px"
						color="var(--fontcolor)"
						name="calendar-1"
					/>
				</div>

				<div class="modal-unavailable__body__warning">
					<div class="modal-unavailable__body__warning__title">
						<TextPlay
							color="var(--fontcolor)"
							weight="semibold"
							:text="message.title"
						/>
					</div>
					<div class="modal-unavailable__body__warning__description">
						<TextPlay
							size="sm"
							color="var(--text-color)"
							:text="message.description"
						/>
					</div>
				</div>

				<div class="modal-unavailable__button">
					<ButtonPlay
						w="97px"
						type="normal"
						:text="$t('unavailable_modal.understood')"
						@click="closeAction"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/pt-br";

export default {
	computed: {
		...mapGetters({
			getModalInfoCourse: "home/getModalInfoCourse",
		}),
		releaseDate() {
			return this.formatDateForLanguage(this.getModalInfoCourse.data.date);
		},
	},
	data() {
		return {
			message: {
				title: "",
				description: "",
			},
		};
	},
	mounted() {
		this.showMessage();
	},
	methods: {
		closeAction() {
			this.$emit("close");
		},
		formatDateForLanguage(date) {
			return moment(date).format("DD/MM/YYYY");
		},
		showMessage() {
			const expr = this.getModalInfoCourse.data.type;

			switch (expr) {
				case 1:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_course"),
						description: this.$t("unavailable_modal.course_fixed_date", { date: this.releaseDate }),
					};
					break;
				case 2:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_class"),
						description: this.$t("unavailable_modal.keep_progress"),
					};
					break;
				case 3:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_class"),
						description: this.$t("unavailable_modal.keep_progress_class_avaliable", { date: this.releaseDate }),
					};
					break;
				case 4:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_module"),
						description: this.$t("unavailable_modal.module_fixed_date", { date: this.releaseDate }),
					};
					break;
				case 5:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_module"),
						description: this.$t("unavailable_modal.keep_module_for_allowed"),
					};
					break;
				case 6:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_module"),
						description: this.$t("unavailable_modal.keep_progress_module_avaliable", { date: this.releaseDate }),
					};
					break;
				case 7:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_class"),
						description: this.$t("unavailable_modal.class_fixed_date", { date: this.releaseDate }),
					};
					break;
				default:
					this.message = {
						title: this.$t("unavailable_modal.unavailable_default"),
						description: this.$t("unavailable_modal.unavailable_fixed_date", { date: this.releaseDate }),
					};
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.modalUnavailable {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	justify-content: center;
	align-items: center;
	background: #00000070;
	z-index: 91002;

	.modal-unavailable {
		background-color: var(--backgroundcolor);
		padding: 20px;
		border-radius: 16px;
		width: 424px;

		&__header {
			display: flex;
			justify-content: flex-end;

			.isax {
				cursor: pointer;
				transition: all 0.3s ease;

				&:hover {
					opacity: 0.5;
				}
			}
		}

		&__body {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;

			&__warning {
				display: flex;
				flex-direction: column;
				gap: 8px;
				padding-bottom: 8px;
			}
		}
	}
}

@media (max-width: 768px) {
	.modalUnavailable {
		.modal-unavailable {
			width: 340px;
		}
	}
}
</style>
