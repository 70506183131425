<template>
    <div class="courses-datagrid__body__row" @click="openCourse(course)">
        <div class="courses-datagrid__body__row__column course-info">
            <div
                class="courses-datagrid__body__row__column--rating"
                v-if="getShowRating"
            >
                <IconSax size="14px" type="bold" name="rating-star" />
                <TextPlay
                    size="xxs"
                    weight="semibold"
                    :text="course.rating ?? '0.0'"
                />
            </div>
            <div
                class="courses-datagrid__body__row__column--unavailable"
                v-if="!course.is_liberated && !getIsAdmin"
            >
                <IconSax
                    size="16px"
                    color="var(--fontcolor)"
                    name="calendar-1"
                />
                <TextPlay
                    size="xs"
                    weight="normal"
                    :text="$t('my_courses.available_soon')"
                />
            </div>
            <div
                class="courses-datagrid__body__row__column--course-name"
            >
                <TextPlay size="sm" weight="normal" :text="course.title" :title="course.title" />
                <div class="courses-datagrid__body__row__column actions-mobile">
                    <IconSax name="arrow-right-3" type="outline" />
                </div>
            </div>
        </div>

        <div class="courses-datagrid__body__row__column certificate-info">
            <div class="courses-datagrid__body__row__column--certificate-icon">
                <IconSax
                    size="20px"
                    name="medal-star"
                    type="bold"
                    color="var(--maincolor)"
                    v-if="isCertificateAvailable(course)"
                />
                <IconSax name="medal-star" type="outline" size="20px" v-else />
            </div>
            <div
                class="courses-datagrid__body__row__column--percentage-progress"
            >
                <TextPlay
                    size="sm"
                    weight="semibold"
                    :text="(course.percentage_complete || 0) + '%'"
                />
            </div>
            <div class="courses-datagrid__body__row__column--see-certificate">
                <router-link
                    v-show="isCertificateAvailable(course)"
                    to="/certificates"
                >
                    <TextPlay
                        size="xs"
                        weight="normal"
                        :text="
                            $t('my_courses.courses_table.body.see_certificate')
                        "
                    />
                </router-link>
            </div>
        </div>

        <div
            class="courses-datagrid__body__row__column actions"
            @click="openCourse(course)"
        >
            <TextPlay
                size="sm"
                weight="semibold"
                :text="$t('my_courses.courses_table.body.access_course')"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TextPlay from "../common/TextPlay.vue";
import CourseService from "@/services/resources/CourseService";

const serviceCourse = CourseService.build();

export default {
    name: "CourseDatagridRowComponent",
    data() {
        return {
            ratingHome: true,
            courseProgress: 0,
        };
    },
    props: {
        course: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        ...mapGetters({
            getShowRating: "config/getShowRating",
            getIsAdmin: "config/getIsAdmin",
        })
    },

    components: {
        TextPlay,
    },

    methods: {
        ...mapActions({
            openLesson: "courses/actionOpenLesson",
            actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
        }),

        async openCourse(course) {
            if (course.is_liberated === true || this.getIsAdmin) {
                this.$emit("click");
                const params = await this.openLesson({
                    courseId: course.id,
                    moduleId: course.module_id,
                    lessonId: course.lesson_id,
                });
                await this.$router.push({
                    name: "course-lesson",
                    params,
                });
            } else {
                const data = {
                    rule: course.rule_liberation,
                    date: course.liberated_at,
                    type: 1,
                };

                this.actionShowModalInfoCourse({
                    active: true,
                    data,
                });
            }
        },

        seeCertificate() {
            this.$router.push({ path: "/certificates" });
        },

        isCertificateAvailable(course) {
            return (
                course.percentage_release_certificate !== null &&
                course.percentage_complete >=
                    course.percentage_release_certificate
            );
        }
    },
};
</script>

<style scoped lang="scss">
.courses-datagrid__body__row {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px 0;

    [data-theme="light"] & {
        border-top: 1px solid var(--neutral-gray-100);

        &:last-child {
            border-bottom: 1px solid var(--neutral-gray-100);
        }

        &:hover {
            background-color: var(--neutral-gray-50);
        }
    }

    [data-theme="dark"] & {
        border-top: 1px solid var(--neutral-gray-700);

        &:last-child {
            border-bottom: 1px solid var(--neutral-gray-700);
        }

        &:hover {
            background-color: var(--neutral-gray-800-1);
        }
    }

    &__column {
        display: flex;
        flex-direction: row;
        gap: 16px;
        cursor: pointer;

        &.course-info {
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 2;
            max-width: 700px;
        }

        &--course-name {
            display: inline-grid;
            align-items: center;
            flex-grow: 1;

            color: var(--fontcolor);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;

            
            & > p {
                width: 95%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow-wrap: break-word;
                border-radius: 0;
            }
        }

        &.certificate-info {
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 2;
            align-items: center;
            gap: 8px;
            white-space: nowrap;
        }

        &.actions {
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 2;
            max-width: 56px;
            align-items: center;
            margin-left: auto;

            color: var(--maincolor);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
        }

        &.actions-mobile {
            display: none;
        }

        &--rating {
            color: var(--rating-gold-500);
            display: flex;
            align-items: center;
            gap: 4px;

            ::v-deep svg {
                path {
                    fill: var(--rating-gold-500);
                }
            }

            div {
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }
        }

        &--unavailable {
            color: var(--neutral-gray-400);
            display: flex;
            flex-shrink: 0;
            align-items: center;
            gap: 4px;

            div {
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }

        &--percentage-progress {
            color: var(--fontcolor);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
        }

        &--see-certificate {
            a {
                color: var(--maincolor);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }

        &--actions {
            flex: 0;
            color: var(--maincolor);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            justify-content: right;
        }

        &--actions-mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .courses-datagrid__body__row {
        flex: 0 0 100%;
        flex-direction: column;

        [data-theme="light"] & {
            border-top: none;
            border-bottom: 1px solid var(--neutral-gray-100);
        }

        [data-theme="dark"] & {
            border-top: none;
            border-bottom: 1px solid var(--neutral-gray-700);
        }

        &__column {
            &.course-info {
                flex-wrap: wrap;
                justify-content: space-between;
                align-self: flex-start;
                flex: 1 1 100%;
                width: 100%;
                gap: 8px;
            }

            &--course-name {
                width: 100%;
            }

            &.certificate-info {
                flex-wrap: nowrap;
                flex: 0;
                align-self: flex-start;

                ::v-deep svg {
                    width: 16px;
                    height: 16px;
                }
            }

            &.actions {
                display: none;
            }

            &.actions-mobile {
                float: right;
                display: inline-flex;
                margin-left: auto;
                align-items: stretch;
            }

            &--see-certificate {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
