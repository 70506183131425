// state.js
export default {
    listAllCourses: {
        courses: {},
        courseCount: 0,
        meta: {
            total: 0,
            per_page: 25,
            current_page: 1,
            last_page: 1,
        },
    },
};
