// state.js
export default {
  modalInfoCourse: {
    active: false,
    data: {},
  },
  modalBuyCourse: {
    active: false,
    data: {},
  },
  modalFirstAccess: {
    active: false,
  },
  modalTermsOfUse: {
    active: false,
  },
  openedAccordionCategories: 0,
  rearrange: false,
  allCategories: [],
  allCourses: [],
  homeData: {
    courses: [],
    categories: [],
  },
  loadingModule: false,
};
