import Vue from "vue"
import Feedback from "./template/Feedback.vue"
import SidebarModal from "./template/SidebarModal.vue"

Vue.component('Feedback', Feedback)
Vue.component('SidebarModal', SidebarModal)

// Gamefication
export { default as ModalGameficationNewMedal } from "./ModalGameficationNewMedal.vue"
export { default as ModalDeleteSite } from "./ModalDeleteSite.vue"
export { default as ModalMemberCreate } from "./ModalMemberCreate.vue"
export { default as ModalExportMemberReport } from "./ModalExportMemberReport.vue"
export { default as ModalTestWebhook } from "./ModalTestWebhook.vue"
export { default as ModalDeleteWebhook } from "./ModalDeleteWebhook.vue"
export { default as ScheduledMaintenance } from "./ScheduledMaintenance.vue"
export { default as ModalMyPurcharses } from "./ModalMyPurcharses.vue"
export { default as ModalConditionalLinks } from "./ModalConditionalLinks.vue"
export { default as ModalConditionalLinksDiscard } from "./ModalConditionalLinksDiscard.vue"
export { default as ModalConditionalLinksDelete } from "./ModalConditionalLinksDelete.vue"