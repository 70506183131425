<template>
    <div class="wrapper">
        <div
            class="courses-datagrid"
            v-if="coursesByCategory"
            :name="categoryTitle"
        >
            <TextPlay
                class="courses-datagrid__category-title"
                :text="categoryTitle"
            />

            <div class="courses-datagrid__header">
                <div class="courses-datagrid__header__row">
                    <div
                        class="courses-datagrid__header__row__column"
                        @click="
                            sortCoursesBy(
                                'title',
                                sortOptions.titleCurrentOrientation
                            )
                        "
                        name="course-name-column"
                    >
                        <IconSax size="14px" type="bold" name="arrow-3" />
                        <div
                            class="courses-datagrid__header__row__column--title"
                        >
                            {{ $t("my_courses.courses_table.columns.name") }}
                        </div>
                    </div>
                    <div
                        class="courses-datagrid__header__row__column"
                        @click="
                            sortCoursesBy(
                                'percentage_complete',
                                sortOptions.progressCurrentOrientation
                            )
                        "
                        name="progress-column"
                    >
                        <IconSax size="14px" type="bold" name="arrow-3" />
                        <div
                            class="courses-datagrid__header__row__column--title"
                        >
                            {{
                                $t("my_courses.courses_table.columns.progress")
                            }}
                        </div>
                    </div>
                    <div
                        class="courses-datagrid__header__row__column--actions"
                    ></div>
                </div>

                <div class="courses-datagrid__header--mobile">
                    <SimpleSelectPlay
                        :title="$t('my_courses.courses_table.mobile.title')"
                        :options="sortOptions.selectOptions"
                        @input="(id) => sortCoursesBy('', '', id)"
                    />
                </div>
            </div>

            <div class="courses-datagrid__body">
                <CourseDatagridRowComponent
                    v-for="course in courses"
                    :key="course.id"
                    :course="course"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import ButtonPlay from "../common/ButtonPlay.vue";
import SimpleSelectPlay from "../common/SimpleSelectPlay.vue";
import TextPlay from "../common/TextPlay.vue";
import CourseDatagridRowComponent from "./CourseDatagridRowComponent.vue";

export default {
    name: "CoursesDatagridComponent",
    props: {
        categoryTitle: {
            type: String,
            default: "",
        },
        coursesByCategory: {
            type: Array,
            default: () => [],
        },
    },

    components: {
        TextPlay,
        CourseDatagridRowComponent,
        ButtonPlay,
        SimpleSelectPlay,
    },

    data() {
        return {
            isSortOptionsMenuOpen: false,
            sortOptions: {
                titleCurrentOrientation: "",
                progressCurrentOrientation: "",
                selectOptions: [
                    {
                        id: 1,
                        label: this.$t(
                            "my_courses.courses_table.mobile.options.a_z"
                        ),
                    },
                    {
                        id: 2,
                        label: this.$t(
                            "my_courses.courses_table.mobile.options.z_a"
                        ),
                    },
                    {
                        id: 3,
                        label: this.$t(
                            "my_courses.courses_table.mobile.options.bigger_progress"
                        ),
                    },
                    {
                        id: 4,
                        label: this.$t(
                            "my_courses.courses_table.mobile.options.lower_progress"
                        ),
                    },
                ],
            },
            courses: [],
        };
    },

    computed: {
        ...mapGetters({
            getIsMobile: "getIsMobile",
        }),
    },

    methods: {
        openSortOptionsMenu() {
            this.isSortOptionsMenuOpen = !this.isSortOptionsMenuOpen;
        },

        sortNormalize(str) {
            return str
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
        },

        sort(column, orientation) {
            const courses = _.sortBy(this.coursesByCategory, (item) => {
                const value = item[column];

                // Handle numeric columns
                if (typeof value === "number") {
                    return value;
                }

                // Handle string columns with potential numbers
                if (typeof value === "string") {
                    return value
                        .split(/(\d+)/)
                        .map((part) => {
                            const num = parseInt(part);
                            return isNaN(num)
                                ? this.sortNormalize(part)
                                : String(num).padStart(10, "0");
                        })
                        .join("");
                }

                return value;
            });

            return orientation === "desc"
                ? courses.reverse()
                : orientation === "asc"
                ? courses
                : this.coursesByCategory;
        },

        sortCoursesBy(column, orientation, mobileOrientation) {
            let iconSort = null;

            // mobile
            if (column === "" && orientation === "") {
                if (mobileOrientation === 1 || mobileOrientation === 2) {
                    this.courses = this.sort(
                        "title",
                        mobileOrientation === 1 ? "asc" : "desc"
                    );
                }

                if (mobileOrientation === 3 || mobileOrientation === 4) {
                    this.courses = this.sort(
                        "percentage_complete",
                        mobileOrientation === 3 ? "desc" : "asc"
                    );
                }
            }

            // desktop
            if (column === "title") {
                this.resetSorting("progress");
                iconSort = document.querySelector(
                    '[name="' +
                        this.categoryTitle +
                        '"] [name="course-name-column"] svg'
                );
                iconSort.classList.remove("activeAsc", "activeDesc");

                if (orientation === "") {
                    this.sortOptions.titleCurrentOrientation = "asc";
                    iconSort.classList.add("activeAsc");
                }

                if (orientation === "asc") {
                    this.sortOptions.titleCurrentOrientation = "desc";
                    iconSort.classList.add("activeDesc");
                }

                if (orientation === "desc") {
                    this.sortOptions.titleCurrentOrientation = "";
                    iconSort.classList.remove("activeAsc", "activeDesc");
                }

                this.courses = this.sort(
                    column,
                    this.sortOptions.titleCurrentOrientation
                );
            }

            if (column === "percentage_complete") {
                this.resetSorting("title");
                iconSort = document.querySelector(
                    '[name="' +
                        this.categoryTitle +
                        '"] [name="progress-column"] svg'
                );
                iconSort.classList.remove("activeAsc", "activeDesc");

                if (orientation === "") {
                    this.sortOptions.progressCurrentOrientation = "asc";
                    iconSort.classList.add("activeAsc");
                }

                if (orientation === "asc") {
                    this.sortOptions.progressCurrentOrientation = "desc";
                    iconSort.classList.add("activeDesc");
                }

                if (orientation === "desc") {
                    this.sortOptions.progressCurrentOrientation = "";
                    iconSort.classList.remove("activeAsc", "activeDesc");
                }

                this.courses = this.sort(
                    column,
                    this.sortOptions.progressCurrentOrientation
                );
            }
        },

        resetSorting(column) {
            let iconSort = null;

            if (column === "title") {
                this.sortOptions.titleCurrentOrientation = "";
                iconSort = document.querySelector(
                    '[name="' +
                        this.categoryTitle +
                        '"] [name="course-name-column"] svg'
                );
            }

            if (column === "progress") {
                this.sortOptions.progressCurrentOrientation = "";
                iconSort = document.querySelector(
                    '[name="' +
                        this.categoryTitle +
                        '"] [name="progress-column"] svg'
                );
            }
            iconSort.classList.remove("activeAsc", "activeDesc");
        },
    },

    watch: {
        coursesByCategory: {
            handler(newVal) {
                this.courses = newVal;
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 40px;
}

.courses-datagrid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 16px;

    &__category-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: var(--fontcolor);
    }

    &__header {
        width: 100%;

        &__row {
            max-width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            padding: 8px 0;

            &__column {
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                &--title {
                    color: var(--fontcolor);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                }

                &[name="course-name-column"] {
                    display: flex;
                    flex-basis: 0;
                    flex-grow: 1;
                    flex-shrink: 2;
                    max-width: 700px;

                    ::v-deep svg {
                        &.activeAsc {
                            #Vector {
                                fill: var(--maincolor);
                            }
                        }

                        &.activeDesc {
                            #Vector_2 {
                                fill: var(--maincolor);
                            }
                        }
                    }
                }

                &[name="progress-column"] {
                    display: flex;
                    flex-basis: 0;
                    flex-grow: 1;
                    flex-shrink: 2;

                    ::v-deep svg {
                        &.activeAsc {
                            #Vector {
                                fill: var(--maincolor);
                            }
                        }

                        &.activeDesc {
                            #Vector_2 {
                                fill: var(--maincolor);
                            }
                        }
                    }
                }

                &--actions {
                    display: flex;
                    flex-basis: 0;
                    flex-grow: 1;
                    flex-shrink: 2;
                    max-width: 56px;
                }
            }
        }

        &--mobile {
            display: none;
        }
    }

    &__body {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .courses-datagrid {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__header {
            &__row {
                display: none;
            }

            &--mobile {
                display: block;
            }
        }
    }
}
</style>
