// actions.js
import router from "@/router/index";

/* Services */
import HomeService from "@/services/resources/HomeService";
const serviceHome = HomeService.build();

import SidebarService from "@/services/resources/SidebarService";
const sidebar = SidebarService.build();

import CategoryService from "@/services/resources/CategoryService";
const serviceCategory = CategoryService.build();

import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();


/* utils */
import scrollToElement from "@/utils/scrollToElement";

import notify from "@/services/libs/notificacao";

export default {
  actionUpdateCoursesList({ commit }, payload) {
    commit("home/UPDATE_COURSES_LIST", payload, { root: true });
  },

  actionToggleRearrangeCourse({ commit }, payload) {
    commit("home/SET_REARRANGE", payload, { root: true });
  },


  disableEmptyState({ commit }, key) {

    return new Promise(async (resolve, reject) => {
      try {
        const emptyState = await serviceHome.disableEmptyState(key);
        commit("home/SET_EMPTY_STATE", emptyState, { root: true });
      } catch (error) {
        reject(error);
      }
    });
  },

  actionRearrangeOrderCourse({ commit }, array) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = {
          id: "/order",
          array,
        };

        const response = await serviceCourse.postIDArray(data);

        resolve(response);
        notify("sucesso", "Ordenação Salva!");
      } catch (error) {
        notify("erro", "Erro ao Salvar Ordenação!");
        reject(error);
      }
    });
  },

  async actionFetchCourseAvailability({ commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const keys =
          "/meta?keys[]=sale_link&keys[]=sale_video&keys[]=sale_video_thumb&keys[]=sale_button_txt&keys[]=sale_description&keys[]=sale_video_type";
        const response = await serviceCourse.read(id);
        const description = await serviceCourse.read(id + keys);
        resolve({ ...response, ...description });
      } catch (error) {
        reject(error);
      }
    });
  },

  actionShowModalInfoCourse({ commit }, payload) {
    commit("home/SHOW_MODAL_INFO", payload, { root: true });
  },

  actionShowModalBuyCourse({ commit }, payload) {
    commit("home/SHOW_MODAL_BUY", payload, { root: true });
  },

  async actionShowModalFirstAccess({ commit, dispatch, state }) {
    if (state.modalFirstAccess.actived) {
      return false
    }

    const first_access_enabled = this.getters["config/getSettings"]?.["first_access_enabled"]

    const optoutId = await dispatch("user/actionGetMetaSiteScoped", "first_access_optout", { root: true })
    const optout = this.getters["user/getMetas"]?.[optoutId]
    const getIsAdmin = this.getters["config/getIsAdmin"]

    if (!getIsAdmin && !optout) {
      commit("SHOW_MODAL_FIRST_ACCESS", {
        actived: true
      })

      return first_access_enabled
    }
  },

  async actionShowModalTermsOfUse({ commit, rootGetters }) {
    const siteId = rootGetters["auth/getMe"]?.current?.currentSite?.id
    const terms_of_use_enabled = rootGetters["config/getSettings"]?.["termsOfUseActivated"]
    const optoutId = `_termos_${siteId}`
    const optout = rootGetters["user/getMetas"]?.[optoutId]
    const getIsAdmin = rootGetters["config/getIsAdmin"]

    if (!getIsAdmin && !optout) {
      commit("SHOW_MODAL_TERMS_OF_USE", {
        actived: true
      })

      return terms_of_use_enabled
    }
  },

  async actionFetchCategoriesData({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceCategory.search();
        commit("home/SET_CATEGORIES_DATA", response, { root: true });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  async actionFetchHomeData({ commit, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceHome.search(`page=${payload?.page ?? 1}`);

        if (!getters["getEmptyState"] && getters["getHomeData"]?.courses?.length === 0) {
          const emptyState = await serviceHome.getEmptyState();
          commit("home/SET_EMPTY_STATE", emptyState, { root: true });
        }

        const mergedCourses = payload?.page > 1 ? [
          ...(getters["getHomeData"]?.courses || []),
          ...response.courses,
        ] : response.courses;

        commit(
          "home/SET_HOME_DATA",
          {
            ...response,
            courses: mergedCourses
          },
          { root: true }
        );

        const sortCourses = [
          ...new Set(mergedCourses),
        ].sort((a, b) => a.order - b.order);

        commit("home/UPDATE_COURSES_LIST", sortCourses, { root: true });

        resolve(response);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async actionFetchSidebar() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await sidebar.search();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  async actionOpenedAccordionCategories({ commit }, number) {

    try {
      commit("home/SET_OPEN_ACCORDION_CATEGORIES", number, { root: true });
      if (!number) return;

      commit("home/SET_LOADING_MODULE", true, { root: true });

      const categoriesInHomeData = this.getters["home/getHomeData"].categories

      const existingCategory = categoriesInHomeData.find((category) => category.id == number).courses

      if (existingCategory) {

        const getAllCategories = this.getters["home/getAllCategories"]
        
        const emptyCategory = getAllCategories.find((item) => item.id == number)

        if(emptyCategory === undefined) return

        scrollToElement("accordion-" + number);
        
        commit("home/SET_LOADING_MODULE", false, { root: true });
        return;
      }

      const response = await serviceHome.read(
        "/getCoursesByCategory/" + number
      );

      const homeData = this.getters["home/getHomeData"];

      const updatedCategories = homeData.categories.map(category => {
        if (category.id === number && !category.courses) {
          return { ...category, courses: response };
        }
        return category;
      });

      commit("home/SET_HOME_DATA", { ...homeData, categories: updatedCategories }, { root: true });

      if(response.length === 0) return

      if(response.length > 0) {
        scrollToElement("accordion-" + number);
      }
      
      await commit("home/SET_LOADING_MODULE", false, { root: true });
    } catch (error) {
      await commit("home/SET_LOADING_MODULE", false, { root: true });
    }
  },

  async actionOpenedAccordionCategoriesNoScroll({ commit }, number) {
    try {
      commit("home/SET_OPEN_ACCORDION_CATEGORIES", number, { root: true });
      if (!number) return;

      commit("home/SET_LOADING_MODULE", true, { root: true });

      if (
        this.getters["home/getHomeData"].categories.find(
          (category) => category.id == number
        ).courses
      ) {
        commit("home/SET_LOADING_MODULE", false, { root: true });
        return;
      }

      const response = await serviceHome.read(
        "/getCoursesByCategory/" + number
      );

      commit(
        "home/SET_HOME_DATA",
        {
          ...this.getters["home/getHomeData"],
          categories: this.getters["home/getHomeData"].categories.map(
            (category) => {
              if (category.id == number && !category.courses) {
                return {
                  ...category,
                  courses: response,
                };
              } else {
                return category;
              }
            }
          ),
        },
        { root: true }
      );

      await commit("home/SET_LOADING_MODULE", false, { root: true });
    } catch (error) {
      await commit("home/SET_LOADING_MODULE", false, { root: true });
    }
  },
  async actionNavToCourse({ dispatch }, course) {
    if (course.has_access === false) {
      const response = await dispatch("actionFetchCourseAvailability", course?.id)

      const data = {
        id: response.id,
        title: response.title,
        cover: response.cover,
        button: response.sale_button_txt,
        description: response.sale_description,
        link: response.sale_link,
        video: response.sale_video,
        video_thumb: response.sale_video_thumb,
        video_type: response.sale_video_type
      }

      await dispatch("actionShowModalBuyCourse", {
        active: true,
        data
      });
      return
    }
    if (course.has_access === true && course.is_liberated === false) {
      const data = {
        rule: course.rule_liberation,
        date: course.liberated_at,
        type: 1
      }

      await dispatch("actionShowModalInfoCourse", {
        active: true,
        data
      })
      return
    }

    return router.push({ path: `/curso/${course.id}` })
  }
};
