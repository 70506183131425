import { render, staticRenderFns } from "./BadgePlay.vue?vue&type=template&id=28923ab6&scoped=true&"
import script from "./BadgePlay.vue?vue&type=script&lang=js&"
export * from "./BadgePlay.vue?vue&type=script&lang=js&"
import style0 from "./BadgePlay.vue?vue&type=style&index=0&id=28923ab6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28923ab6",
  null
  
)

export default component.exports