import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
    async actionFetchAllCoursesData({ commit }, payload) {
        const page = payload.page || 1;
        return new Promise(async (resolve, reject) => {
            try {
                const response = await serviceMember.read(
                    `/me/all-courses?page=${page}`
                );
                const prevCoursesData =
                    this.getters["myCourses/getListCourses"];

                if (response.courseCount > 0) {
                    const mergedCourses =
                        page > 1
                            ? Object.keys(response.courses).reduce(
                                  (acc, category) => {
                                      acc[category] = [
                                          ...(prevCoursesData.courses[
                                              category
                                          ] || []),
                                          ...(response.courses[category] || []),
                                      ];
                                      return acc;
                                  },
                                  { ...prevCoursesData.courses }
                              )
                            : response.courses;

                    const payload = {
                        ...response,
                        courses: mergedCourses,
                    };

                    commit("myCourses/SET_LIST_ALL_COURSES_DATA", payload, {
                        root: true,
                    });
                }

                resolve(await response);
            } catch (error) {
                console.error("Error in actionFetchAllCoursesData:", error);
                reject(error);
            }
        });
    },
};
