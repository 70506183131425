<template>
    <Layout contentClass="my-courses">
        <MyCoursesComponent />
    </Layout>
</template>

<script>
import MyCoursesComponent from '@/components/myCourses/MyCoursesComponent.vue';
import Layout from '@/layout/Layout.vue';

export default {
    components: {
        Layout,
        MyCoursesComponent
    },
}
</script>

<style lang="scss" scoped>

</style>