<template>
  <div :class="{
    'my-account': !isMobile,
    'my-account-mobile': isMobile,
  }" data-anima="top">
    <div class="my-account__header">
      <TitlePage :title="$t('minha_conta.title')" />
    </div>
    <div v-if="isMobile" class="my-account__header">
      <div class="memberSince">
        <span class="text-color-item-date">
          {{ $t("minha_conta.member") }}
          <template>
            {{
              getMe?.current?.currentMember?.created_at
              | moment("DD/MM/YYYY HH:mm:ss")
            }}
          </template>
        </span>
      </div>
    </div>

    <modal-upload></modal-upload>

    <template>
      <div class="h-full">
        <TabsPlay enableAllTabs :textSize="isMobile ? 'xs' : 'base'" :tabs="tabs" v-model="tabSelected">
          <template slot="0">
            <div class="profile__content__description">
              <div :class="{ containerx: !isMobile, '': isMobile }">
                <div :class="'container-perfil'">
                  <div class="side-left width100" :class="{ width100: isMobile }">
                    <div class="animationOpacity2 width100" :class="'edicao-perfil'">
                      <div>
                        <div class="coluna">
                          <div>
                            <div class="flexProfile">
                              <div class="spacePic" v-if="preloadImage">
                                <img v-if="picProfile" class="foto-usuario" :class="{ contains: picProfile }"
                                  :src="picProfile" />
                                <div v-else class="foto-usuario">
                                  <span>{{ firstName?.substr(0, 1) }}</span>
                                </div>
                              </div>
                              <div class="adminBar__actions">
                                <label class="custom-button">
                                  <span>{{ $t("minha_conta.txt9") }}</span>
                                  <input style="display: none" type="file" ref="fileInput" @change="croppie" />
                                </label>
                              </div>
                              <b-modal id="modal-crop" size="lg" hide-header hide-footer>
                                <div class="gridClop">
                                  <div class="headerCrop">
                                    <p class="titleCrop">
                                      {{ $t("minha_conta.txt17") }}
                                    </p>
                                    <IconSax @click="clearCrop" style="cursor: pointer" name="closeCrop" />
                                  </div>
                                  <div class="gridCroppie">
                                    <vue-croppie ref="croppieRef" :enableResize="false"
                                      :boundary="{ width: 215, height: 238 }" :viewport="{
                                        width: 150,
                                        height: 150,
                                        type: 'circle',
                                      }">
                                    </vue-croppie>
                                    <img :src="imgDataUrl" />
                                    <div class="footerCrop">
                                      <div>
                                        <ButtonPlay @click="clearCrop" type="feedback-error">
                                          <IconSax name="trashCrop" />
                                        </ButtonPlay>
                                      </div>
                                      <div class="btnCrop">
                                        <ButtonPlay @click="clearCrop" style="margin-right: 20px" type="outline">
                                          <span class="loadImageText">{{
                                            $t("minha_conta.txt16")
                                          }}</span>
                                        </ButtonPlay>

                                        <ButtonPlay @click="crop">
                                          {{ $t("minha_conta.txt5") }}
                                        </ButtonPlay>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </b-modal>
                              <div></div>
                            </div>
                            <div class="infoLoadImage">
                              <p v-if="imgError" class="imgErrorColor">
                                *{{ imgErrorMessage }}
                              </p>
                              <p :class="{ imgErrorColor: imgError }">
                                {{ $t("minha_conta.txt13") }}
                              </p>
                              <p :class="{ imgErrorColor: imgError }">
                                {{ $t("minha_conta.txt14") }}
                              </p>
                              <p :class="{ imgErrorColor: imgError }">
                                {{ $t("minha_conta.txt15") }}
                              </p>
                            </div>
                          </div>

                          <div :class="'edicao-perfil-coluna'">
                            <div class="box-perfil">
                              <div :class="'names-input'">
                                <div class="item">
                                  <label class="text-color-item">{{
                                    $t("minha_conta.name")
                                  }}</label>
                                  <b-form-input v-model="firstName" :placeholder="$t('minha_conta.txt')"></b-form-input>
                                </div>
                                <div class="item">
                                  <label class="text-color-item">{{
                                    $t("minha_conta.document")
                                  }}</label>
                                  <b-form-input v-model="document_number" :placeholder="$t('minha_conta.txt8')" v-mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##',
                                  ]"></b-form-input>
                                </div>
                                <div class="item">
                                  <label class="text-color-item">{{
                                    $t("minha_conta.telephone")
                                  }}</label>
                                  <b-form-input v-model="phoneNumber" v-mask="['(##) ####-####', '(##) #####-####']"
                                    :placeholder="$t('minha_conta.txt3')"></b-form-input>
                                </div>
                                <div class="item">
                                  <div>
                                    <label class="text-color-item">{{
                                      $t("minha_conta.email")
                                    }}</label>
                                  </div>
                                  <div class="item-email">
                                    <b-form-input disabled :value="getMe?.current?.currentMember?.email
                                      " :class="{ width100: isMobile }"
                                      :placeholder="$t('minha_conta.txt7')"></b-form-input>

                                    <div class="info-email" v-b-tooltip.hover="{
                                      customClass: 'tooltip-class',
                                    }" :title="'Não é possível modificar seu email'">
                                      <IconSax name="info-circle" type="outline" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="grid-btn-atualizar">
                              <div>
                                <ButtonPlay size="sm" class="btn-atualizar-perfil" @click="updateDataName">
                                  {{ $t("minha_conta.txt5") }}
                                </ButtonPlay>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isMobile" class="memberSince">
              <span class="text-color-item-date">
                {{ $t("minha_conta.member") }}
                <template>
                  {{
                    getMe?.current?.currentMember?.created_at
                    | moment("DD/MM/YYYY HH:mm:ss")
                  }}
                </template>
              </span>
            </div>
          </template>

          <template slot="1">
            <MyPurchases />
          </template>

          <template slot="2">
            <div class="profile__content__description">
              <div :class="'edicao-perfil-coluna'">
                <form v-bind:autocomplete="autocomplete">
                  <div class="animationOpacity2 box-perfil">
                    <div :class="'names-input'">
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.label_password")
                        }}</label>
                        <input autocomplete="off" v-model="lastPassword" :type="typeLastPassword"
                          :placeholder="$t('minha_conta.placeholder_password')" :class="{ width100: isMobile }" />
                        <IconSax v-if="typeLastPassword == 'password'" class="icon-eye" name="eye-slash"
                          @click="toggleLastVisibility" />
                        <IconSax v-else class="icon-eye" name="eye" @click="toggleLastVisibility" />
                        <p v-if="password_error.status" :class="password_error.class">
                          {{ password_error.message }}
                        </p>
                      </div>
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.label_new_password")
                        }}</label>
                        <input v-bind:autocomplete="autocomplete" v-model="newPassword" @input="checkPassword('new')"
                          :type="typeNewPassword" :placeholder="$t('minha_conta.placeholder_new_password')
                            " :class="{ width100: isMobile }" />
                        <IconSax v-if="typeNewPassword == 'password'" class="icon-eye" name="eye-slash"
                          @click="toggleNewVisibility" />
                        <IconSax v-else class="icon-eye" name="eye" @click="toggleNewVisibility" />
                        <p v-if="new_password_error.status" :class="new_password_error.class">
                          {{ new_password_error.message }}
                        </p>
                      </div>
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.label_confirm_password")
                        }}</label>
                        <input v-bind:autocomplete="autocomplete" v-model="confirmPassword"
                          @input="checkPassword('confirm')" :type="typeConfirmPassword" :placeholder="$t('minha_conta.placeholder_confirm_password')
                            " :class="{ width100: isMobile }" />
                        <IconSax v-if="typeConfirmPassword == 'password'" class="icon-eye" name="eye-slash"
                          @click="toggleConfirmVisibility" />
                        <IconSax v-else class="icon-eye" name="eye" @click="toggleConfirmVisibility" />
                        <p v-if="confirm_new_password_error.status" :class="confirm_new_password_error.class">
                          {{ confirm_new_password_error.message }}
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="grid-btn-atualizar">
                  <div>
                    <ButtonPlay size="sm" class="btn-atualizar-perfil" @click="updatePassword">
                      {{ $t("minha_conta.update_password") }}
                    </ButtonPlay>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template slot="3">
            <div class="profile__content__description">
              <div :class="'edicao-perfil-coluna-idioma'">
                <div class="animationOpacity2" v-if="$route.path === '/minha-conta'">
                  <Locales ref="LocalesRef" />
                </div>
                <div class="grid-btn-atualizar">
                  <div>
                    <ButtonPlay size="sm" class="btn-atualizar-perfil" @click="setLocale">
                      {{ $t("minha_conta.txt5") }}
                    </ButtonPlay>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </TabsPlay>
      </div>
    </template>
  </div>
</template>

<script>
import customIcon from "@/components/configs/icons/custom.vue";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import ModalUpload from "@/components/ModalUpload.vue";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import myUpload from "vue-image-crop-upload";
import Cookies from "js-cookie";
import Locales from "@/components/configs/Locales.vue";
import TitlePage from "@/components/common/TitlePage.vue";
import { mapActions, mapGetters } from "vuex";
import MyPurchases from "./MyPurchases.vue";

export default {
  components: {
    Locales,
    ModalUpload,
    customIcon,
    "my-upload": myUpload,
    TitlePage,
    MyPurchases,
  },
  data() {
    return {
      autocomplete: "off",
      isLoading: false,
      croppieImage: "",
      tabSelected: 0,
      email: "",
      membro: "",
      updated_at: "",
      client: {
        width: 0,
      },
      picProfile: null,
      NewPicProfileId: "",
      NewPicProfileUrl: "",
      logoPainel: null,
      NewLogoId: "",
      NewLogoUrl: "",
      color: "#002363",
      swatches: [["#FF0C37"], ["#8b5aff"], ["#002363"], ["#ffa51a"]],
      ratingHome: true,
      firstName: null,
      document_number: null,
      lastName: null,
      phoneNumber: null,
      show: false,
      preloadImage: true,
      imgError: false,
      imgErrorMessage: "",
      params: {},
      headers: {
        Authorization: Cookies.get("auth_greennCourse"),
      },
      imgDataUrl: "",
      urlPost: process.env.VUE_APP_API_HOST + "/member/meta/picture",
      newPassword: null,
      confirmPassword: null,
      lastPassword: null,
      regexEmojis:
        /[^a-zA-Z0-9\\.\\_\\@\\`\\´\\{\\}\\<\\>\\¨\\+\\-\\=\\#\\$\\%\\^\&\\*\\(\\)\\,\\;\\:\\'\\\"\\/\\?\\~\\!\\[\\\]\\]/g,
      PASSWORD_TYPES: {
        LAST: "last",
        NEW: "new",
        CONFIRM: "confirm",
      },
      typeLastPassword: "password",
      typeNewPassword: "password",
      typeConfirmPassword: "password",
      showLastPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      password_error: {
        message: "",
        status: false,
        class: "imgErrorColor",
      },
      new_password_error: {
        message: "",
        status: false,
        class: "imgErrorColor",
      },
      confirm_new_password_error: {
        message: "",
        status: false,
        class: "imgErrorColor",
      },
      MAX_FILE_SIZE: 2 * 1024 * 1024, // 2 MB
      MIN_DIMENSION: 300,
      MAX_DIMENSION: 1500,
      SUPPORTED_FORMATS: ["image/jpeg", "image/png"],
      tabs: [
        {
          id: 'registration',
          icon: 'profile-2user',
          label: this.$t('minha_conta.txt10'),
        },
        {
          id: 'purchases',
          icon: 'dollar-circle',
          label: this.$t('minha_conta.purchases.title'),
        },
        { id: 'password', icon: 'lock', label: this.$t('minha_conta.txt11') },
        { id: 'language', icon: 'flag-2', label: this.$t('minha_conta.txt12') },
      ]
    };
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
      isMobile: "layout/getIsMobile",
    }),
  },
  methods: {
    ...mapActions({
      actionFetchMe: "auth/actionFetchMe",
      actionGetUserLogged: "auth/actionGetUserLogged",
      actionEditProfile: "auth/actionEditProfile",
      actionUpdateAvatar: "auth/actionUpdateAvatar",
      actionFetchSettings: "config/actionFetchSettings",
    }),
    async getDimensions(img) {
      return new Promise((resolve, reject) => {
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = () => reject(new Error("Error loading image."));
      });
    },

    isSupportedFileType(type) {
      return this.SUPPORTED_FORMATS.includes(type);
    },

    isFileSizeValid(fileSize) {
      return fileSize < this.MAX_FILE_SIZE;
    },

    areDimensionsValid({ width, height }) {
      return (
        width >= this.MIN_DIMENSION &&
        width <= this.MAX_DIMENSION &&
        height >= this.MIN_DIMENSION &&
        height <= this.MAX_DIMENSION
      );
    },

    async handleFileSelect(event) {
      const files = event.target.files;
      if (!files.length)
        return { msg: this.$t("minha_conta.txt18"), isValid: false };

      const selectedFile = files[0];
      if (!this.isSupportedFileType(selectedFile.type)) {
        return { msg: this.$t("minha_conta.txt19"), isValid: false };
      }

      if (!this.isFileSizeValid(selectedFile.size)) {
        return { msg: this.$t("minha_conta.txt20"), isValid: false };
      }

      try {
        const img = new Image();
        const imgURL = URL.createObjectURL(selectedFile);
        img.src = imgURL;
        const dimensions = await this.getDimensions(img);

        URL.revokeObjectURL(imgURL);

        if (!this.areDimensionsValid(dimensions)) {
          const msg =
            dimensions.width < this.MIN_DIMENSION ||
              dimensions.height < this.MIN_DIMENSION
              ? this.$t("minha_conta.txt21")
              : this.$t("minha_conta.txt22");
          return { msg, isValid: false };
        }
        return { msg: this.$t("minha_conta.txt23"), isValid: true };
      } catch (error) {
        console.error(error);
        return { msg: this.$t("minha_conta.txt24"), isValid: false };
      }
    },
    async croppie(e) {
      this.$root.$emit("bv::show::modal", "modal-crop");
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      let valid = await this.handleFileSelect(e);

      if (!valid.isValid) {
        this.imgErrorMessage = valid.msg;
        this.imgError = !valid.isValid;
        this.$root.$emit("bv::hide::modal", "modal-crop");
      } else {
        this.imgError = false;
        var reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.croppieRef.bind({
            url: e.target.result,
          });
        };

        reader.readAsDataURL(files[0]);
      }
    },
    clearCrop() {
      this.$refs.fileInput.value = null;
      this.imgDataUrl = "";
      this.$root.$emit("bv::hide::modal", "modal-crop");
    },
    crop() {
      let options = {
        circle: true,
        // size: { width: 238, height: 150 },
        size: { width: 300, height: 300 },
        format: "jpeg",
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.imgDataUrl = this.croppieImage = output;
        this.cropSuccess(this.croppieImage);
        this.clearCrop();
      });
    },
    checkHasError(data) {
      const validatePassword = (field, minLength = 8) => {
        const fieldsMap = {
          password: this.$t("minha_conta.txt25"),
          new_password: this.$t("minha_conta.txt26"),
          confirm_new_password: this.$t("minha_conta.txt27"),
        };

        if (!data[field]) {
          this.setValidationMessage(
            this[`${field}_error`],
            `${this.$t("minha_conta.txt28")} ${fieldsMap[field]} ${this.$t(
              "minha_conta.txt29"
            )} `
          );
          return false;
        } else if (data[field].length < minLength) {
          this.setValidationMessage(
            this[`${field}_error`],
            `${this.$t("minha_conta.txt30")} ${minLength} ${this.$t(
              "minha_conta.txt31"
            )}`
          );
          return false;
        } else {
          this[`${field}_error`].status = false;
        }

        if (field === "confirm_new_password") {
          if (data.new_password !== data.confirm_new_password) {
            this.setValidationMessage(
              this[`${field}_error`],
              this.$t("minha_conta.txt32")
            );
            return false;
          } else {
            this.confirm_new_password_error.status = false;
          }
        }

        return true;
      };

      if (
        !validatePassword("password") ||
        !validatePassword("new_password") ||
        !validatePassword("confirm_new_password")
      ) {
        return false;
      }

      return true;
    },
    checkPassword(passwordType) {
      const passwordMap = {
        last: { value: "lastPassword", error: "password_error" },
        new: { value: "newPassword", error: "new_password_error" },
        confirm: {
          value: "confirmPassword",
          error: "confirm_new_password_error",
        },
      };

      const { value, error } = passwordMap[passwordType] || {};
      if (!value || !error) return false;

      let password = this[value]?.replace(this.regexEmojis, "") || "";
      const errorObject = this[error];
      const isPasswordValid = password.length >= 8;
      const isConfirmType = passwordType === "confirm";
      const isNewType = passwordType === "new";
      const passwordsMatch = this.newPassword === this.confirmPassword;

      if (isPasswordValid && isNewType && !passwordsMatch) {
        this.setValidationMessage(errorObject, this.$t("minha_conta.txt32"));
        if (isNewType) {
          if (passwordsMatch) {
            this.setValidationMessage(
              this.confirm_new_password_error,
              this.$t("minha_conta.txt33"),
              "imgCorrectColor"
            );
          } else {
            if (this.confirmPassword) {
              this.setValidationMessage(
                this.confirm_new_password_error,
                this.$t("minha_conta.txt32")
              );
            }
          }
          this.setValidationMessage(
            errorObject,
            this.$t("minha_conta.txt33"),
            "imgCorrectColor"
          );
        }
      } else if (isPasswordValid && !isConfirmType) {
        this.setValidationMessage(
          errorObject,
          this.$t("minha_conta.txt33"),
          "imgCorrectColor"
        );
        if (passwordsMatch && isNewType) {
          this.setValidationMessage(
            this.confirm_new_password_error,
            this.$t("minha_conta.txt33"),
            "imgCorrectColor"
          );
        }
      } else if (
        password === this.newPassword &&
        isPasswordValid &&
        isConfirmType
      ) {
        this.setValidationMessage(
          errorObject,
          this.$t("minha_conta.txt33"),
          "imgCorrectColor"
        );
      } else {
        this.setValidationMessage(
          errorObject,
          isConfirmType
            ? this.$t("minha_conta.txt32")
            : "A senha deve ter no mínimo 8 caracteres."
        );
        if (isNewType && !passwordsMatch && this.confirmPassword) {
          this.setValidationMessage(
            this.confirm_new_password_error,
            this.$t("minha_conta.txt32")
          );
        }
      }

      return errorObject.status;
    },
    setValidationMessage(
      messegaObject,
      message,
      imgClass = "imgErrorColor",
      status = true
    ) {
      Object.assign(messegaObject, {
        status: status,
        message: message,
        class: imgClass,
      });
    },
    toggleLastVisibility() {
      this.showLastPassword = !this.showLastPassword;
      this.typeLastPassword = this.showLastPassword ? "text" : "password";
    },
    toggleNewVisibility() {
      this.showNewPassword = !this.showNewPassword;
      this.typeNewPassword = this.showNewPassword ? "text" : "password";
    },
    toggleConfirmVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
      this.typeConfirmPassword = this.showConfirmPassword ? "text" : "password";
    },
    setLocale() {
      let selectedCountry = this.$refs.LocalesRef.lists.filter((item) =>
        item.sigla
          .toLowerCase()
          .includes(this.$refs.LocalesRef.pais_code.toLowerCase().trim())
      );

      if (selectedCountry) {
        this.$refs.LocalesRef.setLocale(selectedCountry[0].sigla);
      }
    },
    updatePassword() {
      var data = {
        id: "change-password",
        password: this.lastPassword,
        new_password: this.newPassword,
        confirm_new_password: this.confirmPassword,
      };

      if (this.checkHasError(data)) {
        serviceMember
          .postID(data)
          .then(() => {
            this.newPassword = null;
            this.lastPassword = null;
            this.confirmPassword = null;
            this.$bvToast.toast(this.$t("minha_conta.errors.update"), {
              title: this.$t("minha_conta.txt34"),
              variant: "success",
              autoHideDelay: 6300,
              appendToast: true,
            });
          })
          .catch((err) => {
            console.error(err);
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0];
            }
            if (msg !== "") {
              this.setValidationMessage(this.password_error, msg);
            }
          });
      }
    },
    toggleShow() {
      this.$root.$emit("bv::toggle::modal", "modal-crop");
      this.show = !this.show;
    },
    convertBase64ToImage(base64) {
      const image = new Image();
      image.src = base64;
      return URL.createObjectURL(this.base64ToBlob(base64));
    },
    base64ToBlob(base64) {
      const byteString = atob(base64.split(",")[1]);
      const mimeString = "image/jpeg";
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    cropSuccess(imgDataUrl) {
      const decodedImage = this.base64ToBlob(imgDataUrl);
      this.picProfile = imgDataUrl;
      this.NewPicProfileUrl = decodedImage;
      this.updateProfilePic();
    },
    cropUploadSuccess(user) {
      this.preloadImage = false;
      this.actionUpdateAvatar(user.value);
      setTimeout(() => {
        this.$bvToast.toast(this.$t("minha_conta.errors.perfil"), {
          title: this.$t("minha_conta.txt34"),
          variant: "success",
          autoHideDelay: 6300,
          appendToast: true,
        });
        this.$forceUpdate();
        this.preloadImage = true;
        this.$root.$emit("changedprofilepic");
      }, 1000);
    },
    cropUploadFail() {
      this.$bvToast.toast(this.$t("minha_conta.errors.perfil_error"), {
        title: this.$t("minha_conta.txt36"),
        variant: "erro",
        autoHideDelay: 6300,
        appendToast: true,
      });

      this.show = false;
    },
    updateDataName() {
      var names = this.firstName.split(" ");
      var lastName = names.slice(1).join(" ");

      const data = {
        id: "meta",
        first_name: names[0],
        last_name: lastName,
        name: names[0] + " " + lastName,
        cellphone: this.phoneNumber,
        document_number: this.document_number,
      };

      const camposVazios = Object.keys(data).filter((key) => {
        const value = data[key];
        return value === null || value === undefined || value === "";
      });

      if (camposVazios.length > 0) {
        this.$bvToast.toast(this.$t("minha_conta.errors.data_error"), {
          title: this.$t("minha_conta.txt35"),
          variant: "danger",
          autoHideDelay: 6300,
          appendToast: true,
        });
        return false;
      }

      let loader = this.$loading.show();

      this.actionEditProfile(data)
        .then(() => {
          this.$bvToast.toast(this.$t("minha_conta.errors.atualizado"), {
            title: this.$t("minha_conta.txt34"),
            variant: "success",
            autoHideDelay: 6300,
            appendToast: true,
          });
        })
        .catch((err) => console.error(err))
        .finally(() => loader.hide());

      this.actionFetchMe();
      this.actionFetchSettings();
    },
    getUserLogged() {
      let loader = this.$loading.show();
      this.actionGetUserLogged()
        .then((resp) => {
          this.firstName = resp.first_name + " " + resp.last_name;
          this.lastName = resp.last_name;
          (this.phoneNumber = resp.cellphone),
            (this.document_number = resp.document_number),
            (this.updated_at = resp.updated_at);
        })
        .catch((err) => console.error(err))
        .finally(() => loader.hide());
    },
    changedRating() {
      var status = this.ratingHome === true ? "on" : "off";
      let data = {
        id: "rating_home",
        value: status,
      };
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then(() => this.getRating())
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    getRating() {
      let loader = this.$loading.show();
      serviceMeta
        .search("keys[]=rating_home")
        .then((resp) => {
          if (resp.rating_home === null || resp.rating_home === "on") {
            this.ratingHome = true;
          } else {
            this.ratingHome = false;
          }
        })
        .catch((err) => console.error(err))
        .finally(() => loader.hide());
    },
    updateLogoPainel() {
      var data = {
        id: "logo",
        value: this.NewLogoUrl,
      };

      let loader = this.$loading.show();
      serviceMeta
        .postID(data)
        .then(() => {
          this.getLogo();
          this.$root.$emit("changedlogo");
        })
        .catch((err) => console.error(err))
        .finally(() => loader.hide());
    },
    getLogo() {
      let loader = this.$loading.show();
      serviceMeta
        .search("keys[]=logo")
        .then((resp) => {
          this.logoPainel = resp.logo;
        })
        .catch((err) => console.error(err))
        .finally(() => loader.hide());
    },
    updateProfilePic() {
      const formData = new FormData();
      formData.append("value", this.NewPicProfileUrl, "image.jpg");
      formData.append("id", "meta/picture");

      var data = {
        id: "meta/picture",
        value: this.NewPicProfileUrl,
      };

      let loader = this.$loading.show();
      serviceMember
        .postID(formData)
        .then(() => {
          this.getPicProfile();
          this.$root.$emit("changedprofilepic");
        })
        .catch((err) => console.error(err))
        .finally(() => loader.hide());
    },
    getPicProfile() {
      let loader = this.$loading.show();
      serviceMember
        .read("/meta")
        .then((resp) => {
          this.picProfile = resp.picture;
        })
        .catch((err) => console.error(err))
        .finally(() => loader.hide());
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    showModalUploadPic() {
      this.$root.$emit("picprofile");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadLogo() {
      this.$root.$emit("logopainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
  },
  mounted() {
    this.getUserLogged();
    this.getPicProfile();
    this.getRating();

    this.$root.$on("newpicprofile", (data) => {
      this.NewPicProfileId = parseInt(data.id);
      this.NewPicProfileUrl = data.url;
      this.updateProfilePic();
    });

    this.$root.$on("newlogopainel", (data) => {
      this.NewLogoId = parseInt(data.id);
      this.NewLogoUrl = data.url;
      this.updateLogoPainel();
    });
  },
};
</script>

<style scoped lang="scss">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--bg-input-fill-text) !important;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: var(--bg-input-fill-shadow) !important;
}

.my-account {
  padding: 70px 70px;

  &__header {
    display: flex;
    margin-bottom: var(--spacing-16);
  }
}

.my-account-mobile {
  padding: 20px 20px;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 15px;
  margin-left: 15px;
}

.text-color {
  color: var(--fontcolor) !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  padding-bottom: 25px;
  border-bottom: 0.5px solid #ededf0;
}

.text-color-item {
  color: var(--text-color-item) !important;

  @media screen and (max-width: 768px) {
    padding-left: 0px;
    margin-top: 20px;
  }
}

.text-color-item-date {
  color: var(--text-color-item);
}

.hide {
  display: none;
}

.textTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--fontcolor);
  padding-bottom: 4px;
}

.spaceConfigPainel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 53px;
  width: 40em;
  // margin-top: 20px;
}

.spaceConfigPainelMobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 53px;
  margin-top: -20px;
}

.slidebarMobile {
  float: right;
  padding-top: 20px;
}

.container-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.containerx {
  width: 98%;
}

.flex {
  display: flex;
}

.flexProfile {
  display: flex;
  width: 100%;
  background: var(--contentcolor);
  padding: 15px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.spaceColor {
  margin-top: 20px;
  margin-bottom: 20px;

  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    margin-bottom: 5px;
  }
}

.spaceConfigPainel {
  display: grid;
  gap: 22px;
  grid-template-columns: 1fr 1fr;
  // margin-top: 50px;
  width: 100%;
}

.subTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #81858e;
}

.coluna {
  height: auto;
  padding: 24px, 20px, 24px, 20px;
  border-radius: 8px;
  display: flex;
  gap: 24px;
  grid-template-columns: 20% 80%;
  display: grid;

  @media screen and (max-width: 768px) {
    grid-template-columns: none;
  }
}

.btnChangePic {
  height: 45px;
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  width: 250px;
  font-weight: 600;
  font-size: 14px;
  color: #002363;
  background: rgba(0, 228, 160, 0.05);
  border: 2px dashed #002363;
  padding: 0px 15px;
  margin-top: 40px;
  margin-left: 30px;
}

.spaceDados {
  display: flex;
  gap: 5px;
  flex-direction: column;

  p {
    margin-top: 23px;
    margin-bottom: 23px;
  }
}

.logoPainel {
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.spacePic {
  align-items: center;
  display: flex;
}

.picProfile {
  width: 100px;
  height: 100px;
  // background: rgba(129, 133, 142, 0.2);
  // border: 1px solid #81858E;
  box-sizing: border-box;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 13px;

  img {
    width: 100%;
  }
}

.picProfile.picMobile {
  margin-top: 25px;
}

.width100 {
  width: 100% !important;
}

/* === */
.container-perfil {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-perfil-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 15px;
}

.side-left h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 1px 0;
}

.side-left p {
  font-size: 14px;
  margin: 1px 0;
  font-weight: 500;
}

.side-left span {
  color: #81858e;
  font-size: 14px;
  font-weight: 500;
}

.edicao-perfil-coluna,
.edicao-perfil-coluna-idioma {
  padding: 20px 20px;
  width: 100%;
  background: var(--contentcolor);
  border-radius: 10px;
}

.edicao-perfil-coluna-idioma {
  height: 192px;
}

.edicao-perfil-mobile {
  margin: 40px 0;
  width: 100%;
  background: var(--contentcolor);
  border-radius: 10px;
}

.box-perfil {
  width: 100%;
}

.names-input {
  display: grid;
  gap: 10px;
}

.form-control:focus {
  box-shadow: none;
}

.names-input-mobile {
  display: block;
  align-items: flex-start;
}

.names-input .item {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid var(--profile-item-border);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0px;
  }
}

.names-input .item-email {
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    padding: 0px;
  }
}

.info-email {
  position: relative;
  top: 5px;
}

.names-input .item label {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  align-items: center;
  width: 180px;
}

.names-input .item input::placeholder {
  color: var(--text-color-item-placeholder);
}

.names-input .item input {
  border: none;
  font-size: 14px;
  transition: 0.3s;
  width: 50%;

  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }
}

.names-input-mobile .item {
  display: block;
}

.names-input-mobile .item+.item {
  margin-top: 20px;
}

.names-input-mobile .item label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.names-input-mobile .item input {
  border: none;
  height: 55px;
  padding: 20px;
  font-size: 14px;
  transition: 0.3s;
  width: 235px;
}

.btn-atualizar-perfil {
  border: none;
  background: var(--maincolor);
  color: #fff;
  outline: none;
  font-weight: 600;
  height: 48px !important;
  width: 132px !important;
  padding: 0px 12px 0px 12px !important;
  border-radius: 8px;
}

.btn-crop {
  border: none;
  background: var(--maincolor);
  color: #fff;
  outline: none;
  font-weight: 600;
  width: 90px;
  border-radius: 8px;
}

.grid-btn-atualizar {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.side-right {
  width: 100%;
}

.side-right img {
  margin-left: 120px;
  width: 300px;
}

.foto-usuario {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--maincolor);
  object-fit: fill;
  display: flex;
  justify-content: center;
}

.foto-usuario span {
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

.foto-usuario.contains {
  background: none;
}

.lastUpdate {
  display: flex;
  align-items: center;
}

.memberSince {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  margin-right: 10px;
}

.memberSince span,
.lastUpdate span {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #878787;
}

.loadImageText {
  color: var(--maincolor) !important;

  &:hover {
    color: var(--neutral-white) !important;
  }
}

.outlineHover {
  &:hover {
    span {
      color: #fff !important;
    }
  }
}

.infoLoadImage p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #878787;
}

.infoLoadImage {
  margin-top: 15px;
}

.custom-button span {
  color: var(--maincolor);
}

.custom-button {
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid var(--maincolor);
  border-radius: var(--default-radius);
  color: var(--maincolor);

  &:hover {
    background: var(--maincolor);

    ::v-deep .svgCss {
      svg>g>rect {
        fill: var(--neutral-white) !important;
      }
    }

    span {
      color: var(--neutral-white);
    }
  }
}

.gridClop {
  background: var(--modalcropbackgorund);

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.headerCrop {
  padding: 20px;
  border-bottom: 1px solid var(--line-modal-crop);
  display: flex;
  justify-content: space-between;
}

.gridCroppie img {
  display: none;
}

.footerCrop {
  display: grid;
  justify-content: space-between;
  padding: 20px;
  grid-template-columns: 2fr 2fr;
  border-top: 1px solid var(--line-modal-crop);
}

.btnCrop {
  display: flex;
  justify-content: flex-end;
}

.titleCrop {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--title-modal-crop);
}

.imgErrorColor {
  color: #ff3932 !important;
}

.imgErrorColor::before {
  content: " X";
  color: #ff3932;
  margin: 5px;
  font-size: 10px;
}

.imgCorrectColor {
  color: #60b527 !important;
}

.imgCorrectColor::before {
  content: " ✔";
  color: #60b527;
  margin: 5px;
  font-size: 10px;
}

.imgErrorColor,
.imgCorrectColor {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.icon-eye {
  transform: translateX(-100px);
  width: 16px;
  cursor: pointer;
}
</style>
