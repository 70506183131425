<template>
  <b-modal 
    :visible="visible" 
    :id="id" 
    class="modal" 
    hide-footer 
    hide-header 
    hide-header-close 
    :size="size"
    :no-close-on-backdrop="preventClose"
    :no-close-on-esc="preventClose"
    @close="$emit('close')"
    @change="$emit('change')"
    @show="$emit('show')"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')">
    <div
        :is="form ? 'form' : 'div'"
         @submit.prevent="$emit('submit')"
         class="h-full flex-col">
      <div class="header">
        <div class="headerTitle">{{ title }}</div>
        <div class="headerSubtitle mb-3">
          {{ subtitle }}
        </div>
        <div class="btnClose">
          <span @click="close">
            <IconSax name="close-circle" />
          </span>
        </div>
      </div>
      <Divider v-if="title" :margin="gap" />
      <div class="content flex-col flex-1">
        <slot />
      </div>
      <Divider v-if="confirmLabel" :margin="gap" />
      <div class="footer">
        <slot name="footer">
          <div class="flex-row justify-end">
            <ButtonPlay v-if="confirmLabel" @click="confirm">
              <b>{{ confirmLabel }}</b>
            </ButtonPlay>
          </div>
        </slot>
      </div>
    </div>
  </b-modal>
</template>
  
<script>
import ButtonPlay from "@/components/common/ButtonPlay.vue";

export default {
  components: {
    ButtonPlay,
  },
  model: {
    event: "change",
    prop: "visible"
  },
  props: {
    id: {
      required: true
    },
    visible: {
      default: false,
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    confirmText: {
      type: String
    },
    confirmLabel: {
      type: String
    },
    gap: {
      default: 1.5
    },
    size: {
      default: "xl"
    },
    preventClose: {
      default: false
    },
    form: {
      type: Boolean,
      default: true
    },
    submit: {
      type: Function,
      default: () => false
    }
  },
  methods: {
    close() {
      this.$emit("close")
      if (!this.preventClose) {
        this.$root.$emit("bv::hide::modal", this.id, "#btnShow");
      }
    },
    async confirm() {
      this.$emit("confirm");
    }
  },
  computed: {

  }
};
</script>
  
<style lang="scss" scoped>
::v-deep .modal {

  & .modal-content {
    padding: 2rem !important;
    margin: 0 !important;
  }

  & .modal-body {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.modal {
  padding: 1rem;
}

.content {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.content::-webkit-scrollbar {
  display: none;
}

.header {
  position: relative;
  min-height: 1.5rem;
  background-color: var(--backgroundcolor);
}

.headerTitle {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: var(--title-color);
  margin-bottom: 5px;
}

.headerSubtitle {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--subtitle-color);
}

.btnClose {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  ::v-deep {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: var(--modal-close-color);
      }
    }
  }
}


</style>
