var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.link ? 'router-link' : 'button',{tag:"element",class:[
        'custom-button',
        _vm.size,
        _vm.type,
        _vm.rounded ? 'rounded' : '',
        _vm.fullWidth ? 'fullWidth' : '',
        { disabled: _vm.disabled },
        { 'skeleton skeleton-dark skeleton-button': !_vm.loader && _vm.loading },
        { mobile: _vm.isMobile },
        _vm.$vnode.data.staticClass,
        _vm.dense ? 'dense' : '',
    ],style:(_vm.computedStyle),attrs:{"to":_vm.link,"type":!_vm.link && _vm.submit ? 'submit' : 'button',"data-testid":"button-play","disabled":_vm.disabled},on:{"click":_vm.emitClick,"mouseover":function($event){return _vm.handleMouseHover(true)},"mouseleave":function($event){return _vm.handleMouseHover(false)}}},[(_vm.icon)?_c('IconSax',{attrs:{"name":_vm.icon,"type":_vm.iconType,"size":_vm.iconSize}}):_vm._e(),_vm._t("default",function(){return [_c('TextPlay',{attrs:{"size":_vm.fontSize,"weight":_vm.fontWeight,"text":_vm.text,"color":_vm.color}})]}),(_vm.iconRight)?_c('IconSax',{attrs:{"name":_vm.iconRight,"type":_vm.iconType,"size":_vm.iconSize}}):_vm._e(),(_vm.loading && _vm.loader === 'spinner')?_c('b-spinner',{attrs:{"small":"","variant":"light","label":"Loading"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }